.pricing-card {
  background-color: transparent;
  background-image: linear-gradient(180deg, #2b2338 0%, #0a0118 100%);
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 40px 30px;
  margin-top: 75px;
  color: #9B96B0;
  font-weight: 600;
  font-size: 18px;
  border-radius: 15px;
  /* margin-right: 35px; */
  overflow: hidden;
}

.pricing-card h3 {
  color: rgb(231, 226, 226);
}

.paragraph {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.0px;
  line-height: 23px;
  border-bottom: 1px solid rgb(105, 100, 100);
  padding-bottom: 20px;
  padding-top: 10px;
  margin-right: 20px;
}

.price {
  display: inline-flex;
  padding: 12px 0px;
  border-bottom: 1px solid rgb(105, 100, 100);
  margin-right: 20px;
}

.price h2 {
  font-size: 47px;
  font-weight: 600;
  color: white;
}

.price p {
  color: #f775e5;
  font-size: 15px;
  font-weight: 550;
  letter-spacing: 1.0px;
  margin-top: 15px;
  margin-left: 25px;
}

.include-list {
  padding: 15px 0px;
}

.include-list h5 {
  color: white;
}

.include-list ul li {
  font-size: 17px;
  font-weight: 450;
  padding: 10px 0px;
  border-bottom: 1px solid rgb(32, 32, 32);
  margin-right: 20px;
}

.purchaseBtn {
  font-size: 16px;
  letter-spacing: 2.2px;
  background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
  color: white;
  width: 340px;
  border-radius: 20px;
  padding: 7px 15px;
  border: none;
  font-weight: 600;
  margin-left: 5px;
}

.price-line {
  font-size: 16px;
  padding: 20px 0px;
  line-height: 19px;
}

@media screen and (max-width:1183px) {
  .purchaseBtn {
    width: auto;
  }
}

@media screen and (max-width:1119px) {
  .pricing-card {
    padding: 20px 10px;
    margin-left: 3px;
  }

  .pricing-card h3 {
    font-size: 20px;
  }

  .paragraph {
    font-size: 13px;
    line-height: 17px;
    padding-bottom: 21px;
    padding-top: 10px;
    margin-right: 10px;
  }

  .price {
    display: inline-flex;
    padding: 12px 0px;
    margin-right: 20px;
  }

  .price h2 {
    font-size: 43px;
    font-weight: 600;
    color: white;
  }

  .price p {
    color: #f775e5;
    font-size: 13px;
    font-weight: 550;
    letter-spacing: 1.0px;
    margin-top: 15px;
    margin-left: 25px;
  }

  .include-list {
    padding: 15px 0px;
  }

  .include-list h5 {
    font-size: 17px;
  }

  .include-list ul li {
    font-size: 14px;
    padding: 7px 0px;
    margin-right: 20px;
  }

  .purchaseBtn {
    font-size: 14px;
    letter-spacing: 2.2px;
    background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
    color: white;
    width: auto;
    border-radius: 20px;
    padding: 7px 15px;
    border: none;
    font-weight: 600;
    margin-left: 5px;
  }

  .price-line {
    font-size: 14px;
    padding: 20px 10px;
    line-height: 19px;
  }
}

@media screen and (max-width:991px) {
  .price-card-sec div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pricing-card {
    background-color: transparent;
    background-image: linear-gradient(180deg, #2b2338 0%, #0a0118 100%);
    height: auto;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 40px 40px;
    margin-top: 45px;
    color: #9B96B0;
    font-weight: 600;
    font-size: 18px;
    border-radius: 15px;
    /* margin-right: 35px; */
    overflow: hidden;
  }

  .pricing-card h3 {
    color: rgb(231, 226, 226);
    font-size: 23px;
  }

  .paragraph {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.0px;
    line-height: 23px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 20px;
    text-align: center;
  }

  .price {
    display: inline-flex;
    padding: 12px 0px;
    border-bottom: 1px solid rgb(105, 100, 100);
    margin-right: 20px;
  }

  .price h2 {
    font-size: 47px;
    font-weight: 600;
    color: white;
  }

  .price p {
    color: #f775e5;
    font-size: 13px;
    font-weight: 550;
    letter-spacing: 1.0px;
    margin-top: 15px;
    margin-left: 25px;
  }

  .include-list {
    padding: 11px 0px;
  }

  .include-list h5 {
    color: white;
  }

  .include-list ul li {
    font-size: 12px;
    font-weight: 450;
    padding: 10px 0px;
    border-bottom: 1px solid rgb(32, 32, 32);
    margin-right: 20px;
  }

  .purchaseBtn {
    font-size: 16px;
    letter-spacing: 2.2px;
    background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
    color: white;
    width: 340px;
    border-radius: 20px;
    padding: 7px 15px;
    border: none;
    font-weight: 600;
    margin-left: 5px;
  }

  .price-line {
    font-size: 14px;
    padding: 20px 0px;
    line-height: 19px;
    text-align: center;
  }
}

@media screen and (max-width:797px) {
  .price-card-sec div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pricing-card {
    background-color: transparent;
    background-image: linear-gradient(180deg, #2b2338 0%, #0a0118 100%);
    height: auto;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 30px 20px;
    margin-top: 35px;
    color: #9B96B0;
    font-weight: 600;
    font-size: 18px;
    border-radius: 15px;
    /* margin-right: 35px; */
    overflow: hidden;
  }

  .pricing-card h3 {
    color: rgb(231, 226, 226);
    font-size: 25px;
  }

  .paragraph {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.0px;
    line-height: 23px;
    border-bottom: 1px solid rgb(105, 100, 100);
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 20px;
    text-align: center;
  }

  .price {
    display: inline-flex;
    padding: 12px 0px;
    border-bottom: 1px solid rgb(105, 100, 100);
    margin-right: 20px;
  }

  .price h2 {
    font-size: 47px;
    font-weight: 600;
    color: white;
  }

  .price p {
    color: #f775e5;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: 1.0px;
    margin-top: 15px;
    margin-left: 25px;
  }

  .include-list {
    padding: 15px 0px;
  }

  .include-list h5 {
    color: white;
  }

  .include-list ul li {
    font-size: 17px;
    font-weight: 450;
    padding: 10px 0px;
    border-bottom: 1px solid rgb(32, 32, 32);
    margin-right: 20px;
  }

  .purchaseBtn {
    font-size: 16px;
    letter-spacing: 2.2px;
    background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
    color: white;
    width: 340px;
    border-radius: 20px;
    padding: 7px 15px;
    border: none;
    font-weight: 600;
    margin-left: 5px;
  }

  .price-line {
    font-size: 16px;
    padding: 20px 0px;
    line-height: 19px;
    text-align: center;
  }
}

@media screen and (max-width:560px) {
  .price-card-sec div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pricing-card {
    background-color: transparent;
    background-image: linear-gradient(180deg, #2b2338 0%, #0a0118 100%);
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 30px 20px;
    margin-top: 35px;
    color: #9B96B0;
    font-weight: 600;
    font-size: 18px;
    border-radius: 15px;
    /* margin-right: 35px; */
    overflow: hidden;
  }

  .pricing-card h3 {
    color: rgb(231, 226, 226);
    font-size: 25px;
  }

  .paragraph {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.0px;
    line-height: 23px;
    border-bottom: 1px solid rgb(105, 100, 100);
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 20px;
    text-align: center;
  }

  .price {
    display: inline-flex;
    padding: 12px 0px;
    border-bottom: 1px solid rgb(105, 100, 100);
    margin-right: 20px;
  }

  .price h2 {
    font-size: 47px;
    font-weight: 600;
    color: white;
  }

  .price p {
    color: #f775e5;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: 1.0px;
    margin-top: 15px;
    margin-left: 25px;
  }

  .include-list {
    padding: 15px 0px;
  }

  .include-list h5 {
    color: white;
  }

  .include-list ul li {
    font-size: 13px;
    font-weight: 450;
    padding: 10px 0px;
    border-bottom: 1px solid rgb(32, 32, 32);
    margin-right: 20px;
  }

  .purchaseBtn {
    font-size: 16px;
    letter-spacing: 2.2px;
    background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
    color: white;
    width: 340px;
    border-radius: 20px;
    padding: 7px 15px;
    border: none;
    font-weight: 600;
    margin-left: 5px;
  }

  .price-line {
    font-size: 15px;
    padding: 20px 0px;
    line-height: 19px;
    text-align: center;
  }
}

@media screen and (max-width:500px) {
 
  .pricing-card {
    background-color: transparent;
    background-image: linear-gradient(180deg, #2b2338 0%, #0a0118 100%);
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 30px 20px;
    margin-top: 35px;
    color: #9B96B0;
    font-weight: 600;
    font-size: 18px;
    border-radius: 15px;
    /* margin-right: 35px; */
    overflow: hidden;
  }

  .pricing-card h3 {
    color: rgb(231, 226, 226);
    font-size: 25px;
  }

  .paragraph {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.0px;
    line-height: 23px;
    border-bottom: 1px solid rgb(105, 100, 100);
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 20px;
    text-align: center;
  }

  .price {
    display: inline-flex;
    padding: 12px 0px;
    border-bottom: 1px solid rgb(105, 100, 100);
    margin-right: 20px;
  }

  .price h2 {
    font-size: 47px;
    font-weight: 600;
    color: white;
  }

  .price p {
    color: #f775e5;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: 1.0px;
    margin-top: 15px;
    margin-left: 25px;
  }

  .include-list {
    padding: 15px 0px;
  }

  .include-list h5 {
    color: white;
  }

  .include-list ul li {
    font-size: 17px;
    font-weight: 450;
    padding: 10px 0px;
    border-bottom: 1px solid rgb(32, 32, 32);
    margin-right: 20px;
  }

  .purchaseBtn {
    font-size: 16px;
    letter-spacing: 2.2px;
    background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
    color: white;
    width: 340px;
    border-radius: 20px;
    padding: 7px 15px;
    border: none;
    font-weight: 600;
    margin-left: 5px;
  }

  .price-line {
    font-size: 16px;
    padding: 20px 0px;
    line-height: 19px;
    text-align: center;
  }
}
@media screen and (max-width:380px) {
 
  .pricing-card {
    background-color: transparent;
    background-image: linear-gradient(180deg, #2b2338 0%, #0a0118 100%);
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 30px 20px;
    margin-top: 35px;
    color: #9B96B0;
    font-weight: 600;
    font-size: 18px;
    border-radius: 15px;
    /* margin-right: 35px; */
    overflow: hidden;
  }

  .pricing-card h3 {
    color: rgb(231, 226, 226);
    font-size: 25px;
  }

  .paragraph {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.0px;
    line-height: 23px;
    border-bottom: 1px solid rgb(105, 100, 100);
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 20px;
    text-align: center;
  }

  .price {
    display: inline-flex;
    padding: 12px 0px;
    border-bottom: 1px solid rgb(105, 100, 100);
    margin-right: 20px;
  }

  .price h2 {
    font-size: 40px;
    font-weight: 600;
    color: white;
  }

  .price p {
    color: #f775e5;
    font-size: 12px;
    font-weight: 550;
    letter-spacing: 1.0px;
    margin-top: 15px;
    margin-left: 25px;
  }

  .include-list {
    padding: 15px 0px;
  }

  .include-list h5 {
    color: white;
  }

  .include-list ul li {
    font-size: 14px;
    font-weight: 450;
    padding: 10px 0px;
    border-bottom: 1px solid rgb(32, 32, 32);
    margin-right: 20px;
  }

  .purchaseBtn {
    font-size: 14px;
    letter-spacing: 2.2px;
    background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
    color: white;
    width: auto;
    border-radius: 20px;
    padding: 7px 15px;
    border: none;
    font-weight: 600;
    margin-left: 5px;
  }

  .price-line {
    font-size: 13px;
    padding: 20px 0px;
    line-height: 19px;
    text-align: center;
  }
}