 .wrapper {
   padding: 70px 0;
   overflow-x: hidden;
 }

 .testimonial-content {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-top: 100px;
 }

 .testimonial-content h6 {
   color: #f775e5;
   font-size: 13px;
   font-weight: 750;
   letter-spacing: 0.2px;
   margin-bottom: 28px;
   margin-top: 30px;
 }

 .testimonial-content h1 {
   color: white;
 }

 .testimonial-slider {
   margin-top: 30px;
 }

 .my-slider {
   padding: 0px !important;
   margin: 0px !important;
 }

 .slide img {
   height: 70px;
   width: 70px;
   border-radius: 50%;
 }

 .slick-initialized .slick-slide {
   background-color: #454447;
   color: #fff;
   height: 330px;
   margin: 0 5px 0 0;
   padding-bottom: 0px !important;
   opacity: 0.6;
 }

 .slide {
   display: flex !important;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 30px;
 }

 .slide p {
   padding-top: 20px;
   text-align: center;
   padding-bottom: 30px;
 }

 .slide h6 {
   color: #fff;
   font-style: italic;
   font-size: 20px;
   line-height: 15px;
 }

 .cname {
   color: #f775e5;
   font-style: italic;
 }

 .slick-dots {
   color: #fff;
 }

 .slick-list {
   margin: 0px !important;
   padding: 0px !important;
   height: 400px !important;
 }

 .slick-dots.white-dots li button {
   background-color: #f775e5;
   border-radius: 50%;
   margin-top: -40px !important;
   height: 5px !important;
   width: 5px !important;
 }

 .slick-dots.white-dots li.slick-active button {
   background-color: white;
 }

 @media (max-width: 1024px) {
   .slick-initialized .slick-slide {
     height: auto;
     margin: 0 10px;
   }

   .testimonial-content {
     margin-top: 100px;
   }
 }

 @media (max-width: 768px) {
   .slide p {
     font-size: 14px;
   }
 }

 @media (max-width: 480px) {
   .slide {
     padding: 20px;
   }

   .slick-initialized .slick-slide {
     margin: 0 5px;
   }

   .slide p {
     font-size: 12px;
   }
 }

 @media (max-width: 480px) {
   .testimonial-content h1 {
     font-size: 18px;
   }
 }

 /* Testimonial.css */
 @media (max-width: 1024px) {
   .slick-initialized .slick-slide {
     height: auto;
   }
 }

 @media (max-width: 768px) {
   .slide p {
     font-size: 14px;
   }
 }

 @media (max-width: 480px) {
   .slide {
     padding: 20px;
   }

   .slick-initialized .slick-slide {
     margin: 0;
   }

   .slick-list {
     padding: 0 !important;
   }

   .slick-track {
     display: flex !important;
     align-items: center !important;
   }

   .slide p {
     font-size: 12px;
   }
 }