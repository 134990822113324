/* value section */
.value-section {
  height: auto;
  width: 100%;
  background-color: rgba(15, 5, 32, 0.8);
  padding: 50px;
}

.value-first {
  height: 500px;
  width: 60%;
  /* background-image: url(https://theme.creativemox.com/digimax/wp-content/uploads/sites/7/2024/01/computer-screens-with-data.jpg); */
  background-image: url(../../Assets/ourApproach.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  border-radius: 10px;
  object-fit: cover;

}

.value-firstDiv {
  height: 100%;
  width: 100%;
  color: #f1f1f1;
  /* padding: 10px 20px; */
  position: absolute;
  padding: 10px;
  background: rgba(15, 5, 32, 0.7);
}

.value-content {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-bottom: 50px;
  padding: 15px;
}

.value-slogan {
  color: #ff7fc2;
  font-size: 15px;
  font-weight: 750;
  letter-spacing: 0.2px;
}

.value-content h1 {
  font-size: 38px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 42px;
  margin-bottom: 28px;
  padding-top: 25px;
  width: 93%;
}

.value-content p {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.4;
  color: darkgray;
  margin-bottom: 30px;
  line-height: 25px;
  width: 100%;
}

.value-arrow-top {
  height: auto;
  position: absolute;
  right: 0px;
  background-color: transparent;
  background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
  border-radius: 50%;
  padding: 20px 22px;
}

.right-up-value {
  color: white;
  font-weight: bolder;
  font-size: small;
}

.value-second {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 50px;
}

.value-second-1 {
  background-color: #b47cfd;
  margin-left: 50px;
  border-radius: 15px;
  padding: 30px 20px;
  color: white;
  top: 0;
  height: auto;
  width: 100%;
}

.value-second-1 h6 {
  font-weight: 700;
  padding-bottom: 5px;
}

.value-second-2 h6 {
  font-weight: 700;
  padding-bottom: 5px;
}

.value-second-2 {
  height: auto;
  width: 100%;
  background-color: #f775e5;
  margin-left: 50px;
  margin-top: 30px;
  border-radius: 15px;
  padding: 25px;
  color: white;
   /* background-image: url(../../Assets/mission.jpeg); */
  background-size: cover;
  background-repeat: no-repeat; 
}

.value-second-1 h1 {
  line-height: 20px !important;
}

.value-second-1 p {
  padding-top: 7px;
  line-height: 18px;
}

.value-second-2 h1 {
  line-height: 20px !important;
}

.value-second-2 p {
  padding-top: 7px;
  line-height: 18px;
}

@media screen and (max-width: 1321px) {
  .value-first {
    height: 550px;
    width: 53%;
  }

  .value-slogan {
    color: #ff7fc2;
    font-size: 15px;
    font-weight: 750;
    letter-spacing: 0.2px;
  }

  .value-content h1 {
    font-size: 27px;
    font-weight: 700;
    letter-spacing: 0.3px;
    line-height: 40px;
    margin-bottom: 28px;
    padding-top: 25px;
    width: 90%;
  }

  .value-content p {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4;
    color: darkgray;
    margin-bottom: 30px;
    line-height: 25px;
    width: 100%;
  }

  .value-arrow-top {
    height: auto;
    position: absolute;
    right: 0px;
    background-color: transparent;
    background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
    border-radius: 50%;
    padding: 20px 22px;
  }

  .right-up-value {
    color: white;
    font-weight: bolder;
    font-size: small;
  }

  .value-second {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 10px;
  }

  .value-second-1 {
    height: 260px;
    width: 430px;
    background-color: #b47cfd;
    margin-left: 10px;
    border-radius: 15px;
    padding: 30px;
    color: white;
    top: 0;
  }

  .value-second-1 h6 {
    font-weight: 700;
    padding-bottom: 15px;
  }

  .value-second-2 h6 {
    font-weight: 700;
    padding-bottom: 15px;
  }

  .value-second-2 {
    height: 260px;
    width: 430px;
    background-color: #f775e5;
    margin-left: 10px;
    margin-top: 30px;
    border-radius: 15px;
    padding: 20px;
    color: white;
  }

  .value-second-1 h1 {
    line-height: 20px !important;
    /* padding-bottom: 20px; */
    font-size: 20px;
  }

  .value-second-1 p {
    padding-top: 7px;
    line-height: 18px;
    font-size: 14px;
  }

  .value-second-2 h1 {
    line-height: 20px !important;
    /* padding-bottom: 20px; */
    font-size: 20px;
  }

  .value-second-2 p {
    padding-top: 7px;
    line-height: 18px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1140px) {
  .value-section {
    padding: 50px;
  }

  .value-first {
    height: 460px;
    width: 52%;
    overflow: hidden;
  }

  .value-content h1 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 20px;
    padding-top: 19px;
    width: 90%;
  }

  .value-content p {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.4;
    color: darkgray;
    margin-bottom: 20px;
    line-height: 19px;
    width: 100%;
  }

  .value-second {
    margin-left: 10px;
  }

  /* .value-second-1 {
    height: 260px;
    width: 320px;
    margin-left: 0px;
    border-radius: 15px;
    padding: 40px 20px;
  } */

  /* .value-second-2 {
    height: 260px;
    width: 320px;
    margin-left: 0px;
    margin-top: 20px;
    border-radius: 15px;
    padding: 40px 20px;
    color: white;
  } */

  .value-second-1 {
    height: 220px;
    width: 330px;
    margin-left: 10px;
    padding: 15px;
  }

  .value-second-1 h6 {
    font-weight: 700;
    padding-bottom: 12px;
  }

  .value-second-2 h6 {
    font-weight: 700;
    padding-bottom: 12px;
  }

  .value-second-2 {
    height: 210px;
    width: 330px;
    margin-left: 10px;
    margin-top: 20px;
    border-radius: 15px;
    padding: 15px;
  }

  .value-second-1 h1 {
    line-height: 20px !important;
    /* padding-bottom: 20px; */
    font-size: 15px;
  }

  .value-second-1 p {
    padding-top: 7px;
    line-height: 18px;
    font-size: 11px;
  }

  .value-second-2 h1 {
    line-height: 20px !important;
    /* padding-bottom: 20px; */
    font-size: 15px;
  }

  .value-second-2 p {
    padding-top: 7px;
    line-height: 18px;
    font-size: 11px;
  }
}

@media screen and (max-width: 991px) {
  .value-section {
    padding: 50px;

  }

  .value-first {
    width: 100%;
    overflow: hidden;
    position: relative;

  }

  .value-second {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-left: 0px;
    margin-top: 40px;
  }

  .value-content h1 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 20px;
    padding-top: 25px;
    width: 80%;
  }

  .value-content p {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.4;
    color: darkgray;
    margin-bottom: 20px;
    line-height: 19px;
    width: 100%;
  }

  .value-second-1 h6 {
    font-weight: 700;
    padding-bottom: 12px;
  }

  .value-second-2 h6 {
    font-weight: 700;
    padding-bottom: 12px;
  }

  .value-second-1 h1 {
    line-height: 20px !important;
    font-size: 15px;
  }

  .value-second-1 p {
    padding-top: 7px;
    line-height: 18px;
    font-size: 12px;
  }

  .value-second-2 h1 {
    line-height: 20px !important;
    font-size: 15px;
  }

  .value-second-2 p {
    padding-top: 7px;
    line-height: 18px;
    font-size: 12px;
  }

  .value-second-1 {
    height: 240px;
    width: 410px;
    margin-left: 0px;
    border-radius: 15px;
    padding: 30px 20px;
  }

  .value-second-2 {
    height: 240px;
    width: 410px;
    margin-left: 60px;
    margin-top: 0px;
    border-radius: 15px;
    padding: 30px 20px;
    color: white;
  }
}

@media screen and (max-width: 784px) {
  .value-section {
    padding: 50px;

  }

  .value-first {
    width: 100%;
    overflow: hidden;
    position: relative;

  }

  .value-content h1 {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 15px;
    padding-top: 18px;
    width: 85%;
  }

  .value-content p {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4;
    color: darkgray;
    margin-bottom: 15px;
    line-height: 16px;
    width: 100%;
  }

  .value-second {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-left: 0px;
    margin-top: 40px;
  }

  .value-second-1 {
    height: 240px;
    width: 410px;
    margin-left: 0px;
    border-radius: 15px;
    padding: 30px 20px;
  }

  .value-second-2 {
    height: 240px;
    width: 410px;
    margin-left: 20px;
    margin-top: 0px;
    border-radius: 15px;
    padding: 30px 20px;
    color: white;
  }

  .value-second-1 h6 {
    font-weight: 700;
    padding-bottom: 4px;
  }

  .value-second-2 h6 {
    font-weight: 700;
    padding-bottom: 4px;
  }

  .value-second-1 h3 {
    line-height: 21px !important;
    font-size: 21px;
  }

  .value-second-1 p {
    padding-top: 7px;
    line-height: 18px;
    font-size: 12px;
  }

  .value-second-2 h4 {
    line-height: 21px !important;
    font-size: 21px;
  }

  .value-second-2 p {
    padding-top: 7px;
    line-height: 18px;
    font-size: 12px;
  }


}

@media screen and (max-width: 623px) {
  .value-section {
    padding: 50px 0px;

  }

  .value-first {
    width: 100%;
    overflow: hidden;
    position: relative;

  }

  .value-content h1 {
    font-size: 23px;
    line-height: 25px;
    margin-bottom: 12px;
    padding-top: 14px;
    width: 90%;
  }

  .value-content p {
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.4;
    color: darkgray;
    margin-bottom: 15px;
    line-height: 14px;
    width: 100%;
  }

  .value-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-left: 40px; */
    margin-top: 30px;
    padding: 0px 80px;

  }

  .value-second-1 {
    height: auto;
    width: auto;
    /* margin-left: 25px; */
    border-radius: 15px;
    padding: 40px 20px;
    margin-top: 30px;
    object-fit: cover;

  }

  .value-second-2 {
    height: auto;
    width: auto;
    margin-left: 0px;
    margin-top: 50px;
    border-radius: 15px;
    padding: 40px 20px;
    color: white;

  }

  .value-second-1 h6 {
    font-weight: 700;
    padding-bottom: 4px;
  }

  .value-second-2 h6 {
    font-weight: 700;
    padding-bottom: 4px;
  }

  .value-second-1 h3 {
    line-height: 30px !important;
    font-size: 28px;
  }

  .value-second-1 p {
    padding-top: 7px;
    line-height: 18px;
    font-size: 13px;
  }

  .value-second-2 h4 {
    line-height: 30px !important;
    font-size: 28px;
  }

  .value-second-2 p {
    padding-top: 7px;
    line-height: 18px;
    font-size: 13px;
  }


}

@media screen and (max-width: 523px) {
  .value-section {
    padding: 30px 0px;

  }

  .value-first {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;

  }

  .value-content h1 {
    font-size: 19px;
    line-height: 25px;
    margin-bottom: 12px;
    padding-top: 14px;
    width: 98%;
  }

  .value-content p {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4;
    color: darkgray;
    margin-bottom: 5px;
    line-height: 14px;
    width: 100%;
  }

  .value-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-left: 40px; */
    margin-top: 30px;
    padding: 0px 25px;

  }

  .value-second-1 {
    height: auto;
    width: auto;
    /* margin-left: 25px; */
    border-radius: 15px;
    padding: 30px 20px;
    margin-top: 30px;
    object-fit: cover;

  }

  .value-second-2 {
    height: auto;
    width: auto;
    margin-left: 0px;
    margin-top: 50px;
    border-radius: 15px;
    padding: 30px 20px;
    color: white;

  }

  .value-second-1 h6 {
    font-weight: 700;
    padding-bottom: 4px;
  }

  .value-second-2 h6 {
    font-weight: 700;
    padding-bottom: 4px;
  }

  .value-second-1 h3 {
    line-height: 25px !important;
    font-size: 22px;
  }

  .value-second-1 p {
    padding-top: 9px;
    line-height: 18px;
    font-size: 13px;
  }

  .value-second-2 h4 {
    line-height: 25px !important;
    font-size: 22px;
  }

  .value-second-2 p {
    padding-top: 9px;
    line-height: 18px;
    font-size: 12px;
  }


}

@media screen and (max-width: 380px) {
  .value-section {
    padding: 20px 0px;
  }

  .value-first {
    height: 320px;
    width: 100%;
    overflow: hidden;
    position: relative;
    object-fit: contain;
  }

  .value-content h1 {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 8px;
    padding-top: 10px;
    width: 100%;
  }

  .value-content p {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4;
    color: darkgray;
    margin-bottom: 5px;
    line-height: 10px;
    width: 100%;
  }

  .value-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-left: 40px; */
    margin-top: 20px;
    padding: 0px 0px;
  }

  .value-second-1 {
    height: auto;
    width: auto;
    padding: 20px 20px;
    margin-top: 20px;
    object-fit: cover;
  }

  .value-second-2 {
    height: auto;
    width: auto;
    margin-left: 0px;
    margin-top: 40px;
    border-radius: 15px;
    padding: 20px 20px;
    color: white;

  }

  .value-second-1 h6 {
    font-weight: 700;
    padding-bottom: 4px;
  }

  .value-second-2 h6 {
    font-weight: 700;
    padding-bottom: 4px;
  }

  .value-second-1 h3 {
    line-height: 20px !important;
    font-size: 17px;
  }

  .value-second-1 p {
    padding-top: 9px;
    line-height: 9px;
    font-size: 10px;
  }

  .value-second-2 h4 {
    line-height: 20px !important;
    font-size: 17px;
  }

  .value-second-2 p {
    padding-top: 9px;
    line-height: 9px;
    font-size: 10px;
  }


}