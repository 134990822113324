.singal_page {
  height: auto;
  width: 100%;
  background-color: #0a0118;
  padding: 0px 40px;
  margin: 0px;
  padding-top: 80px;
}

.first-sec {
  height: auto;
  width: 100%;
  background-image: url(../../Assets/lineargradientImg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 0px 20px;
 
}

.first-sec {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.first-sec h1 {
  font-size: 47px;
  color: white;
  font-weight: 650;
  text-align: center;
  width: 80%;
  margin-top: 150px;
  line-height: 65px;
  letter-spacing: 0.7px;
}
.first-sec h6{
  color: #ff7fc2;
  text-align: center;
}
.singal-content {
  margin: 150px 0px;
  margin-bottom: 80px;
  text-align: left;
 
}

.spimg {
  width: 100%;
  height: 100%;
}

.spimg img {
  object-fit: cover;
  height: 500px;
  width: 100%;
  border-radius: 5px;
}

.spimg-content p {
  color: darkgray;
  font-size: 18px;
  text-align: justify;
}
.spimg-content h5{
  color: #ff7fc2;
  margin-bottom: 30px;
}
.spimg-content h6{
color: rgb(216, 215, 213);
font-size: 17px;
letter-spacing: 0.7px;
line-height: 25px;

}
@media screen and (max-width: 1274px) {
  .first-sec h1 {
    font-size: 37px;
    line-height: 50px;
  }
}
@media screen and (max-width: 1031px) {
  .first-sec h1 {
    font-size: 33px;
    line-height: 50px;
  }
}
@media screen and (max-width: 935px) {
  .first-sec h1 {
    font-size: 27px;
    line-height: 40px;
  }
  .spimg-content {
    margin-top: 50px;
  }
}
@media screen and (max-width: 768px) {
  .spimg-content {
    margin-top: 50px;
  }
}
@media screen and (max-width: 691px) {
  .first-sec h1 {
    font-size: 19px;
    line-height: 30px;
    width: 100%;
  }

  .first-sec h6 {
    font-size: 25px;
  }

  .first-sec {
    padding: 0px;
    padding-top: 150px;
  }
}

@media screen and (max-width: 495px) {
  .first-sec h1 {
    font-size: 17px;
    line-height: 25px;
    width: 100%;
    letter-spacing: 0.2px;
  }
}

@media screen and (max-width: 437px) {
  .first-sec h1 {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    letter-spacing: 0.2px;
    margin-top: 30px;
  }

  .first-sec h6 {
    font-size: 12px;
  }

  .first-sec {
    height: 0px;
  }

  .first-sec h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .spimg img {
    object-fit: contain;
    height: 315px;
    width: 100%;
    border-radius: 5px;
  }

  .spimg-content {
    margin-top: 50px;
  }

  .spimg-content p {
    font-size: 15px;

  }
}