#counter {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
  padding: 40px 0px;
  width: 100%;
}

#counter .item {
  width: 270px;
  padding: 0px 20px;
  margin: 0px 10px;
  text-align: left;
  border-right: 1px solid rgb(196, 194, 194);
}

#counter .item .count {
  color: white;
  margin-bottom: 5px;
  font-size: 40px;
}

#counter .item p {
  color: white;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
}

@media screen and (max-width:876px) {
  #counter {
    padding: 30px 0px;
    width: 100%;
  }

  #counter .item {
    width: 250px;
  }

  #counter .item .count {
    font-size: 30px;
  }

  #counter .item p {
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
  }
}

@media screen and (max-width:733px) {
  #counter {
    padding: 30px 0px;
    width: 100%;
  }

  #counter .item {
    width: 250px;
    padding: 0px 10px;
  }

  #counter .item .count {
    font-size: 25px;
  }

  #counter .item p {
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 12px;
  }
}

@media screen and (max-width:586px) {
  #counter {
    padding: 30px 0px;
    width: 100%;
  }

  #counter .item {
    width: 250px;
    padding: 0px 10px;
  }

  #counter .item .count {
    font-size: 20px;
  }

  #counter .item p {
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 9px;
  }
}

@media screen and (max-width:487px) {
  #counter {
    padding: 30px 0px;
    width: 100%;
  }

  #counter .item {
    width: 250px;
    padding: 0px 5px;
  }

  #counter .item .count {
    font-size: 17px;
  }

  #counter .item p {
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 7px;
  }
}

@media screen and (max-width:380px) {
  #counter {
    padding: 30px 0px;
    width: 100%;
  }

  #counter .item {
    width: 250px;
    padding: 0px 5px;
  }

  #counter .item .count {
    font-size: 14px;
  }

  #counter .item p {
    color: white;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 5px;
  }
}