.advantage-section {
  height: auto;
  width: 100%;
  padding-bottom: 40px;
  margin-top: 150px;
}


.advantageImg {
  height: 500px;
  width: 100%;
  background-image: url(../../Assets/advantage.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.4;
  position: relative;
}

.advantage-content-section {
  position: absolute;
  height: auto;
  width: 50%;
  margin-top: -280px;
  color: white;
  background-color: #282038;
  opacity: 0.8;
  margin-left: 70px;
  border: 1px solid rgb(61, 59, 59);
  padding: 60px 50px;
}

.advantage-content h1 {
  opacity: 1;
  color: white;
  font-size: 37px;
  letter-spacing: 0.6;
  font-weight: 700;
  font-family: HEADINGS "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.advantage-content p {
  margin-top: 30px;
}

/* media queries */
@media screen and (max-width:1101px) {
  .advantage-content h1 {
    font-size: 27px;
  }

  .advantage-content p {
    font-size: 15px;
    line-height: 22px;
  }
}

@media screen and (max-width:874px) {
  .advantage-section {
    margin-top: 70px;
  }

  .advantage-content-section {
    width: 60%;
    padding: 50px;
    margin-top: -180px;
    margin-left: 30px;
  }

  .advantageImg {
    height: 430px;
  }

  .advantage-content h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .advantage-content p {
    font-size: 13px;
    line-height: 20px;
    margin-top: 15px;
  }
}

@media screen and (max-width:689px) {
  .advantage-section {
    margin-top: 50px;
  }

  .advantage-content-section {
    width: 60%;
    padding: 30px;
    margin-top: -180px;
    margin-left: 30px;
  }

  .advantageImg {
    height: 400px;
  }

  .advantage-content h1 {
    font-size: 18px;
    line-height: 25px;
  }

  .advantage-content p {
    font-size: 10px;
    line-height: 17px;
    margin-top: 15px;
  }
}

@media screen and (max-width:535px) {
  .advantage-section {
    margin-top: 40px;
  }

  .advantage-content-section {
    width: 70%;
    padding: 30px;
    margin-top: -180px;
    margin-left: 30px;
  }

  .advantageImg {
    height: 350px;
  }

  .advantage-content h1 {
    font-size: 15px;
    line-height: 21px;
  }

  .advantage-content p {
    font-size: 8px;
    line-height: 14px;
    margin-top: 15px;
  }
}

@media screen and (max-width:390px) {
  .advantage-section {
    margin-top: 35px;
  }

  .advantage-content-section {
    width: 60%;
    padding: 10px;
    margin-top: -150px;
    margin-left: 20px;
  }

  .advantageImg {
    height: 270px;
  }

  .advantage-content h1 {
    font-size: 12px;
    line-height: 15px;
  }

  .advantage-content p {
    font-size: 8px;
    line-height: 10px;
    margin-top: 15px;
  }
}