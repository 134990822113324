.contact-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  height: auto;
}

.detail-content {
  padding: 30px 60px;
}

.detail-content h6 {
  color: #f775e5;
  text-align: center;
}

.detail-content h1 {
  color: white;
  text-align: center;
  margin-top: 20px;
  font-weight: 700;
}

.detail-content p {
  color: rgb(180, 177, 177);
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
  padding-bottom: 50px;
}

/* .circleDiv {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #f775e5;
  margin-right: 20px;
} */
.circleDiv {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #f775e5;
  margin-right: 20px;
  display: flex;
  justify-content: center; /* Centering horizontally */
  align-items: center; /* Centering vertically */
}


.details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
}

.detail1-div {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
}

.contact-info {
  width: 50%;
}

.contact-info h4 {
  font-size: 15px;
  font-weight: 700;
  color: white;
}

.contact-info h5 {
  font-size: 17px;
  color: white;
  font-weight: 400;
}

.schedule-section {
  padding-bottom: 40px;
  border-bottom: 1px solid rgb(85, 84, 84);
  border-top: 1px solid rgb(85, 84, 84);
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.social-section {
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.social-section h2 {
  padding-bottom: 15px;
}

.con2 {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: flex-end;
}

@media screen and (max-width:839px) {
  .detail-content h1 {
    font-size: 25px;
  }

  .detail-content p {
    font-size: 12px;
  }

  .contact-info h4 {
    font-size: 13px;
  }

  .contact-info h5 {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width:634px) {
  .detail-content {
    padding: 30px;
  }

  .detail-content h1 {
    font-size: 22px;
  }

  .detail-content p {
    font-size: 9px;
  }

  .contact-info h4 {
    font-size: 12px;
  }

  .social-section h2 {
    font-size: 24px;
  }

  .contact-info h5 {
    font-size: 12px;
    line-height: 18px;
  }

  .con2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .detail1-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .detail-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .detail2-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width:547px) {
  .contact-detail {
    margin-top: -60px;
  }
}

@media screen and (max-width:487px) {
  .contact-detail {
    margin-top: -60px;
  }

  .detail-content h1 {
    font-size: 18px;
  }
  .detail-content p {
    font-size: 12px;
  }
  .detail-content h6 {
    font-size: 11px;
  }

  .social-section h2 {
    font-size: 17px;
  }
  /* ---------- */
  .contact-info h4 {
    font-size: 15px;
    font-weight: 700;
    color: white;
  }
  
  .contact-info h5 {
    font-size: 14px;
    color: white;
    font-weight: 400;
  }
  
}

@media screen and (max-width:380px) {
  .contact-detail {
    margin-top: -80px;
  }

  .detail-content h1 {
    font-size: 15px;
  }

  .detail-content p {
    font-size: 12px;
  }

  .detail-content h6 {
    font-size: 8px;
  }

  .detail-content {
    padding: 30px 10px;
  }

  .social-section h2 {
    font-size: 16px;
  }
  /* ---------- */
  .contact-info h4 {
    font-size: 15px;
    font-weight: 700;
    color: white;
  }
  
  .contact-info h5 {
    font-size: 14px;
    color: white;
    font-weight: 400;
  }
}

@media screen and (max-width:343px) {
  .contact-detail {
    margin-top: 10px;
  }

  .detail-content h1 {
    font-size: 14px;
  }

  .detail-content h6 {
    font-size: 7px;
  }
  
  .detail-content p {
    font-size: 12px;
  }

  .detail-content {
    padding: 30px 8px;
  }

  .social-section h2 {
    font-size: 16px;
  }
  /* ---------- */
  .contact-info h4 {
    font-size: 15px;
    font-weight: 700;
    color: white;
  }
  
  .contact-info h5 {
    font-size: 14px;
    color: white;
    font-weight: 400;
  }
}