.step-section {
  height: auto;
  width: 100%;
  background-color: #110227;
  padding-bottom: 50px;
}

.step-section-content {
  background: url(../../Assets/lineargradientImg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.step-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 150px;

}

.step-head h6 {
  font-weight: 700;
  font-size: 14px;
  color: #f775e5;
  padding-bottom: 15px;
}

.step-head h1 {
  color: white;
  font-weight: 750;
  font-size: 43px;
  width: 55%;
  text-align: center;
}

.step-container {
  padding: 40px;

}

.icon-circle {
  height: 70px;
  width: 70px;
  background-color: #ff7fc2;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: absolute;
  top: 0px;
}

.stepDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgb(37, 37, 37);
  height: auto;
  padding: 20px 0px;
}

.stepDiv h6 {
  color: white;
  font-size: 20px;
  font-weight: 600;
  line-height: 50px;
  margin-top: 60px;
}

.stepDiv p {
  color: rgb(168, 164, 164);
  font-weight: 500;
  padding: 20px 20px;
  text-align: center;
  padding-top: 0px;
  font-size: 15px;
  height: 50px;
}

/* media quries */
@media screen and (max-width:1114px) {
  .step-head h1 {
    font-weight: 600;
    font-size: 38px;
    width: 70%;
  }
}

@media screen and (max-width:754px) {
  .step-head h1 {
    font-weight: 600;
    font-size: 32px;
    width: 80%;
  }
}

@media screen and (max-width:562px) {
  .step-head h1 {
    font-weight: 600;
    font-size: 26px;
    width: 90%;
  }
}

@media screen and (max-width:412px) {
  .step-head h1 {
    font-weight: 600;
    font-size: 20px;
    width: 95%;
  }
}

/* steps responsive */
@media screen and (max-width:1150px) {
  .stepDiv p {
    font-size: 13px;
  }
}

@media screen and (max-width:1010px) {
  .stepDiv p {
    font-size: 12px;
  }

  .step-container {
    padding: 40px 10px;
  }

  .stepDiv h1 {
    font-size: 17px;
  }
}

@media screen and (max-width:991px) {

  .stepDiv-2,
  .stepDiv-4 {
    border-right: none;
  }

  .step4 {
    border-right: none;
  }

  .stepDiv-3,
  .stepDiv-4 {
    border-top: 1px solid rgb(37, 37, 37);
  }

  .stepDiv {
    padding-top: 40px;
  }

  .icon-circle {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .stepDiv-4 {
    margin-left: 0px;
  }
}

@media screen and (max-width:845px) {
  .stepDiv p {
    font-size: 10px;
  }

  .step-container {
    padding: 30px 10px;
  }

  .stepDiv h1 {
    font-size: 15px;
  }
}

@media screen and (max-width:767px) {
  .stepDiv p {
    font-size: 12px;
    width: 60%;
  }

  .step-container {
    padding: 40px 0px;
  }

  .stepDiv {
    margin-top: 30px;
    border: none;
  }

  .stepDiv-4 {
    border: none;
  }

  .stepDiv h1 {
    font-size: 35px;
  }
}

@media screen and (max-width:650px) {
  .stepDiv p {
    font-size: 11px;
    width: 85%;
  }

  .step-container {
    padding: 40px 0px;
  }

  .stepDiv {
    margin-top: 20px;
    border: none;
  }

  .stepDiv h1 {
    font-size: 35px;
  }
}

@media screen and (max-width:575px) {
  .stepDiv p {
    font-size: 16px;
    width: 80%;
  }
}

@media screen and (max-width:450px) {
  .stepDiv p {
    font-size: 14px;
    width: 90%;
  }
}

@media screen and (max-width:350px) {
  .stepDiv p {
    font-size: 12px;
    width: 100%;
  }
}