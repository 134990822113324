.technology-section {
  height: auto;
  width: 100%;
  margin-top: 50px;

}

.tcard-container {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap !important;
  padding-left: 20px;

}

.tcardDiv {
  flex-basis: 31%;
  margin: 10px 10px;
  text-align: left;
  padding: 15px 0px;
  border: 1px solid rgb(48, 46, 46);
  color: white;
  height: auto;
  width: auto;
  padding: 13px;
  border-radius: 20px;

}

.tcard {
  background-color: #21192c;
  border-radius: 20px;
  height: 340px;
  padding: 20px 30px;
  padding-bottom: 10px;

}

.tcard h5 {
  color: white;
  line-height: 23px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 19px;
}

.tcard p {
  color: rgb(199, 193, 193);
  line-height: 25px;
  font-size: 14px;
  text-align: justify;
  word-spacing: normal
}

.arrowBtn {
  position: absolute;
  bottom: 0px;
  margin-bottom: 50px;
}

.t-icon {
  color: #ff7fc2;
  margin-bottom: 20px;
}

.tcardDiv:hover {
  border-color: #c2a9b6;
  box-shadow: 0px 0px 23.9px 0px rgba(127, 57, 153, 0.41);
}

/* media quries */
@media screen and (max-width:1141px) {
  .tcard {
    height: 310px;
  }

  .tcard h5 {
    font-size: 16px;
  }

  .tcard p {
    line-height: 19px;
  }
}

@media screen and (max-width:1014px) {
  .tcard {
    height: 315px;
  }

  .tcard h5 {
    font-size: 13px;
  }

  .tcard p {
    line-height: 17px;
    font-size: 13px;
  }
}

@media screen and (max-width:900px) {
  .tcard-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .tcard {
    height: 340px;
    width: 340px;
  }

  .tcardDiv {
    margin-left: 30px;
    margin-top: 20px;
  }

  .tcard h5 {
    font-size: 24px;
  }

  .tcard p {
    line-height: 23px;
    font-size: 16px;
  }
}

@media screen and (max-width:860px) {
  .tcard-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .tcard {
    height: 330px;
    width: 310px;
  }

  .tcardDiv {
    margin-left: 20px;
    margin-top: 20px;
  }

  .tcard h5 {
    font-size: 22px;
  }

  .tcard p {
    line-height: 23px;
    font-size: 14px;
  }
}

@media screen and (max-width:779px) {
  .tcard-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 0px;
  }

  .tcard {
    height: 330px;
    width: 290px;
  }

  .tcardDiv {
    margin-left: 10px;
    margin-top: 20px;
  }

  .tcard h5 {
    font-size: 19px;
  }

  .tcard p {
    line-height: 23px;
    font-size: 12px;
  }
}

@media screen and (max-width:719px) {
  .tcard-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 0px;

  }

  .tcard {
    height: 280px;
    width: 100%;
    padding: 20px 10px;
  }

  .tcardDiv {
    margin-left: 10px;
    margin-top: 20px;
    flex-basis: 45%;
  }

  .tcard h5 {
    font-size: 17px;
  }

  .tcard p {
    line-height: 18px;
    font-size: 12px;
  }

  .arrowBtn {
    margin-bottom: 30px;
  }
}

@media screen and (max-width:639px) {
  .tcard-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 0px;

  }

  .tcard {
    height: 330px;
    width: 350px;
    padding: 20px;
  }

  .tcardDiv {
    margin-top: 30px;
  }

  .tcard h5 {
    font-size: 23px;
    padding-top: 10px;
  }

  .tcard p {
    line-height: 25px;
    font-size: 14px;
  }

  .arrowBtn {
    margin-bottom: 30px;
  }
}

@media screen and (max-width:456px) {
  .tcard-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 0px;
  }

  .tcard {
    height: 310px;
    width: 100%;
    padding: 20px;
  }

  .tcardDiv {
    margin-top: 30px;
    flex-basis: 100%;
  }

  .tcard h5 {
    font-size: 19px;
    padding-top: 10px;
  }

  .tcard p {
    line-height: 25px;
    font-size: 14px;
  }

  .arrowBtn {
    margin-bottom: 30px;
  }
}

@media screen and (max-width:416px) {
  .tcard-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 0px;
  }

  .tcard {
    height: 300px;
    width: 100%;
    padding: 20px;
  }

  .tcardDiv {
    margin-top: 30px;
    flex-basis: 100%;
  }

  .tcard h5 {
    font-size: 16px;
    padding-top: 8px;
  }

  .tcard p {
    line-height: 17px;
    font-size: 12px;
  }

  .arrowBtn {
    margin-bottom: 42px;
    margin-left: 0px;
  }
}

@media screen and (max-width:340px) {
  .tcard-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .tcard {
    height: 250px;
    width: 100%;
    padding: 10px;
  }

  .tcardDiv {
    margin-top: 20px;
    padding: 5px;
  }

  .tcard h5 {
    font-size: 14px;
    padding-top: 5px;
  }

  .tcard p {
    line-height: 14px;
    font-size: 8px;
  }

  .arrowBtn {
    margin-bottom: 20px;
  }
}