.feature-div {
  background-color: transparent;
  background-image: linear-gradient(180deg, #2b2338 0%, #0a0118 100%);
  height: 430px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 30px;
  margin-top: 75px;
  color: #9B96B0;
  font-weight: 600;
  font-size: 18px;
  border-radius: 15px;
}

.feature-div h3 {
  color: white;
}

.feature-1 {
  padding-bottom: 12px;
  padding-top: 15px;
  border-bottom: 1px solid rgb(31, 30, 30);
}

.feature-div div img {
  margin-right: 15px;
}

.feature-2 {
  padding-bottom: 12px;
  padding-top: 15px;

  border-bottom: 1px solid rgb(43, 42, 42);
}

.feature-3 {
  padding-bottom: 12px;
  border-bottom: 1px solid rgb(43, 42, 42);
  padding-top: 15px;
}

.feature-4 {
  padding-bottom: 12px;
  border-bottom: 1px solid rgb(56, 53, 53);
  padding-top: 15px;
}

.feature-5 {
  padding-bottom: 12px;
  border-bottom: 1px solid rgb(71, 69, 69);
  padding-top: 15px;
}

.firstpara {
  padding-bottom: 0px !important;
}

.descriptionbtn-div {
  display: flex;
  justify-content: center;
}

.descriptionBtn {
  font-size: 16px;
  letter-spacing: 2.2px;
  background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
  color: white;
  width: auto;
  border-radius: 20px;
  padding: 7px 40px;
  border: none;
  font-weight: 600;
  margin-left: 5px;
}

@media screen and (max-width:1180px) {
  .feature-div {
    overflow: hidden;
    margin-top: 100px;
  }

  .descriptionBtn {
    font-size: 13px;
  }
}

@media screen and (max-width:991px) {
  .feature-div {
    margin-top: 0px;
  }
}

@media screen and (max-width:364px) {
  .descriptionBtn {
    padding: 8px 30px;
  }

  .feature-div {
    margin-top: 0px;
  }
}

@media screen and (max-width:332px) {
  .descriptionBtn {
    padding: 8px 35px;
    font-size: 10px;
    margin-top: 20px;
  }

  .feature-1 span,
  .feature-2 span,
  .feature-3 span,
  .feature-4 span,
  .feature-5 span {
    font-size: 12px;
  }

  .feature-div {
    margin-top: 0px;
  }
}