.partner-section {
  height: auto;
  width: 100%;
  margin-top: 70px;
}

.partner-head {
  padding: 30px;
  height: 200px;
  width: 100%;
}

.pheading {
  color: white;
  font-weight: 750;
  font-size: 43px;
  width: 55%;
}

.ppara {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.5;
  color: darkgray;
  margin-bottom: 30px;
}

.main-partnership-section {
  padding: 40px;
}

.pmac {
  width: 350px;
  height: 300px;
}

.p1-large {
  background-color: #140131;
  padding: 10px 0px;
  width: 580px;
  border-radius: 10px;
  height: auto;
}

.p1-large-a {
  padding: 20px 0px 40px 30px;
}

.mac-section {
  padding: 0px;
  margin-left: -60px;
}

.boostDiv {
  height: 300px;
  width: 330px;
  background-color: rgb(71, 67, 67);
  border-radius: 10px;
}

.boost {
  height: 415px;
  width: 330px;
  margin-top: -100px;
}

.p1-large-a h4 {
  color: #b47cfd;
  font-weight: 400;
}

.p1-large-a p {
  color: rgb(155, 148, 148);
  font-weight: 500;
  font-size: 14px;
  padding: 7px 0px;
}

.pbtn {
  width: 140px;
  background-color: #b47cfd;
  border-radius: 30px;
  padding: 7px 15px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: white;
  letter-spacing: 1.5px;
  position: relative;
}

.pbtn:hover {
  background-color: white;
  color: black;
}

.pbtn2 {
  background-color: #0a0118;
  width: 140px;
  border-radius: 30px;
  padding: 7px 15px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: white;
  letter-spacing: 1.5px;
  position: relative;

}

.pbtn2:hover {
  background-color: white;
  color: black;
}

.boost-content {
  margin-top: -320px;
  padding: 30px 20px;

}

.boostDiv h4 {
  color: white;
  top: 0px;
  width: 60%;
  padding: 10px;
}

.boostDiv p {
  color: rgb(155, 148, 148);
  padding-right: 50px;
  font-size: 15px;
  font-weight: 500;
  width: 80%;
}

.ratingDiv {
  background-color: #160927;
  padding: 60px 30px;
  margin-top: 20px;
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 330px;
  border-radius: 10px;
}

.ratingDiv h1 {
  font-weight: 700;
  font-size: 45px;
}

.ratingDiv h5 {
  color: rgb(240, 229, 229);
  padding-bottom: 15px;
}

.ratingDiv p {
  text-align: center;
  color: rgb(155, 148, 148);
  font-weight: 400;
  line-height: 20px;
  font-size: 15px;
  margin-top: 20px;
}

.checked {
  color: #eeb02b;
}

.p2-large {
  width: 585px;
  height: 320px;
  background-color: #b47cfd;
  border-radius: 10px;
  margin-left: 30px;
  margin-top: 25px;
}

.p2-large-b {
  padding: 50px 20px;
  color: white;
}

.p2-large-b h4 {
  font-weight: 400;
}

.mobile {
  height: 350px;
  width: 300px;
  margin-top: -30px;
}

.p2-large-b p {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.5;
  color: rgb(241, 236, 236);
  margin-bottom: 30px;
}

.p-last {
  height: 675px;
  width: 280px;
  background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.tele-icon {
  height: 70px;
  width: 70px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tele {
  color: #b47cfd;
}

.p-last h5 {
  color: white;
  margin-top: 20px;
}

.p-last p {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.callnow {
  width: 130px;
  padding: 6px 15px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 30px;
  background-color: #0a0118;
  border: none;
  color: white;

}

.callnow:hover {
  background-color: white;
  color: black;
}

@media screen and (max-width: 1100px) {
  .pheading {
    font-size: 30px;
  }

  .ppara p {
    font-size: 12px;
  }
}

@media screen and (max-width: 991px) {
  .partner-section {
    margin-top: 50px;
  }

  .pheading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .pheading h1 {
    text-align: center;
    width: 70%;
  }

  .ppara {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 25px;
  }

  .ppara p {
    width: 60%;
    font-size: 12px;
    text-align: center;
  }
}

@media screen and (max-width: 696px) {
  .partner-section {
    margin-top: 40px;
  }

  .pheading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .pheading h1 {
    text-align: center;
    width: 90%;
    font-size: 28px;
  }

  .ppara {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }

  .ppara p {
    width: 70%;
    font-size: 9px;
    text-align: center;
  }
}

@media screen and (max-width: 496px) {
  .partner-section {
    margin-top: 20px;
  }

  .pheading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .pheading h1 {
    text-align: center;
    width: 95%;
    font-size: 22px;
  }

  .ppara {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }

  .ppara p {
    width: 75%;
    font-size: 8px;
    text-align: center;
  }
}

@media screen and (max-width: 391px) {
  .partner-section {
    margin-top: 20px;
  }

  .pheading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .pheading h1 {
    text-align: center;
    width: 95%;
    font-size: 17px;
  }

  .ppara {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }

  .ppara p {
    width: 90%;
    font-size: 8px;
    text-align: center;
  }
}

@media screen and (max-width: 391px) {
  .partner-section {
    margin-top: 20px;
    overflow: hidden;
  }

  .pheading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .pheading h1 {
    text-align: center;
    width: 95%;
    font-size: 14px;
    line-height: 19px;
  }

  .ppara {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
  }

  .ppara p {
    width: 95%;
    font-size: 7px;
    text-align: center;
  }
}

@media screen and (max-width:1310px) {
  .p1-large {
    width: 820px;
    padding: 30px;
  }

  .boostDiv {
    width: 820px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 130px;
    height: 400px;
    margin-left: 0px;
  }

  .boost {
    height: 540px;
    width: 820px;
    margin-top: -256px;
  }

  .boost-content p {
    font-size: 16px;
    width: 60%;
  }

  .ratingDiv {
    width: 820px;
    margin-top: 50px;
  }

  .p2-large {
    width: 820px;
    margin-top: 50px;
    margin-left: 10px;
    padding: 30px;
    height: auto;
    padding-bottom: 0px;
  }

  .mobile {
    width: 100%;
  }

  .p-last {
    height: 1670px;
    width: 280px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

  .tele-icon {
    margin-bottom: 50px;
  }

  .p-last h5 {
    color: white;
    margin-top: 20px;
    /* margin-bottom: 40px; */
  }

  .p-last p {
    text-align: center;
    font-size: 15px;
    padding: 10px 25px;
    margin-bottom: 70px;
    margin-top: 30px;
  }

  .callnow {
    width: 130px;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    background-color: #0a0118;
    border: none;
    color: white;
    margin-top: 70px;
  }
}

@media screen and (max-width:1196px) {
  .p1-large {
    width: 740px;
    padding: 30px;
  }

  .boostDiv {
    width: 740px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 130px;
    height: 400px;
    margin-left: 0px;
  }

  .boost {
    height: 540px;
    width: 740px;
    margin-top: -256px;
  }

  .boost-content p {
    font-size: 16px;
    width: 60%;
  }

  .ratingDiv {
    width: 740px;
    margin-top: 50px;
  }

  .p2-large {
    width: 740px;
    margin-top: 50px;
    margin-left: 10px;
    padding: 30px;
    height: auto;
    padding-bottom: 0px;
  }

  .mobile {
    width: 100%;
  }

  .p-last {
    height: 1670px;
    width: 260px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

  .tele-icon {
    margin-bottom: 50px;
  }

  .p-last h5 {
    color: white;
    margin-top: 20px;
    /* margin-bottom: 40px; */
  }

  .p-last p {
    text-align: center;
    font-size: 15px;
    padding: 10px 25px;
    margin-bottom: 70px;
    margin-top: 30px;
  }

  .callnow {
    width: 130px;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    background-color: #0a0118;
    border: none;
    color: white;
    margin-top: 70px;
  }
}

@media screen and (max-width:1108px) {
  .p1-large {
    width: 650px;
    padding: 30px;
  }

  .boostDiv {
    width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 130px;
    height: 400px;
    margin-left: 0px;
  }

  .boost {
    height: 540px;
    width: 650px;
    margin-top: -256px;
  }

  .boost-content p {
    font-size: 16px;
    width: 60%;
  }

  .ratingDiv {
    width: 650px;
    margin-top: 50px;
  }

  .p2-large {
    width: 650px;
    margin-top: 50px;
    margin-left: 10px;
    padding: 30px;
    height: auto;
    padding-bottom: 0px;
  }

  .mobile {
    width: 100%;
  }

  .p-last {
    height: 1670px;
    width: 250px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

  .tele-icon {
    margin-bottom: 50px;
  }

  .p-last h5 {
    color: white;
    margin-top: 20px;
    /* margin-bottom: 40px; */
  }

  .p-last p {
    text-align: center;
    font-size: 15px;
    padding: 10px 25px;
    margin-bottom: 70px;
    margin-top: 30px;
  }

  .callnow {
    width: 130px;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    background-color: #0a0118;
    border: none;
    color: white;
    margin-top: 70px;
  }
}

@media screen and (max-width:1000px) {
  .p1-large {
    width: 550px;
    padding: 15px;
  }

  .p1-large-a h4 {
    font-size: 18px;
  }

  .p1-large-a p {
    font-size: 13px;
  }

  .pmac {
    width: 300px;
    height: 250px;
  }

  .boostDiv {
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    height: 250px;
    margin-left: 0px;
  }

  .boost {
    height: 365px;
    width: 550px;
    margin-top: -176px;
  }

  .boost-content {
    margin-top: -238px;
  }

  .boost-content p {
    font-size: 12px;
    width: 60%;
  }

  .boost-content h4 {
    font-size: 17px;
  }

  .ratingDiv {
    width: 550px;
    margin-top: 30px;
    height: 250px;
  }

  .p2-large {
    width: 550px;
    margin-top: 30px;
    margin-left: 10px;
    padding: 10px;
    height: 250px;
    padding-bottom: 0px;
  }

  .mobile {
    width: 100%;
    height: 265px;
  }

  .p2-large-b h4 {
    font-size: 17px;
  }

  .p2-large-b p {
    font-size: 12px;
  }

  .p-last {
    height: 1180px;
    width: 250px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin-left: -50px;
  }

  .tele-icon {
    margin-bottom: 50px;
  }

  .p-last h5 {
    color: white;
    margin-top: 20px;
    /* margin-bottom: 40px; */
  }

  .p-last p {
    text-align: center;
    font-size: 15px;
    padding: 10px 25px;
    margin-bottom: 70px;
    margin-top: 30px;
  }

  .callnow {
    width: 130px;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    background-color: #0a0118;
    border: none;
    color: white;
    margin-top: 70px;
  }
}

@media screen and (max-width:902px) {
  .p1-large {
    width: 500px;
    padding: 10px;
  }

  .p1-large-a h4 {
    font-size: 15px;
  }

  .p1-large-a p {
    font-size: 10px;
  }

  .pmac {
    width: 250px;
    height: 200px;
  }

  .boostDiv {
    width: 490px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    height: 250px;
    margin-left: 0px;
  }

  .boost {
    height: 365px;
    width: 500px;
    margin-top: -176px;
  }

  .boost-content {
    margin-top: -238px;
  }

  .boost-content p {
    font-size: 12px;
    width: 60%;
  }

  .boost-content h4 {
    font-size: 17px;
  }

  .ratingDiv {
    width: 500px;
    margin-top: 30px;
    height: 250px;
  }

  .p2-large {
    width: 500px;
    margin-top: 30px;
    margin-left: 10px;
    padding: 10px;
    height: 250px;
    padding-bottom: 0px;
  }

  .mobile {
    width: 100%;
    height: 265px;
  }

  .p2-large-b h4 {
    font-size: 17px;
  }

  .p2-large-b p {
    font-size: 12px;
  }

  .p-last {
    height: 1140px;
    width: 250px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin-left: -50px;
  }

  .tele-icon {
    margin-bottom: 50px;
  }

  .p-last h5 {
    color: white;
    margin-top: 20px;
    /* margin-bottom: 40px; */
  }

  .p-last p {
    text-align: center;
    font-size: 15px;
    padding: 10px 25px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .callnow {
    width: 130px;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    background-color: #0a0118;
    border: none;
    color: white;
    margin-top: 0px;
  }
}

@media screen and (max-width:824px) {
  .p1-large {
    width: 400px;
    padding: 10px;
  }

  .p1-large-a h4 {
    font-size: 12px;
  }

  .p1-large-a p {
    font-size: 8px;
  }

  .pmac {
    width: 200px;
    height: 160px;
  }

  .boostDiv {
    width: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    height: 250px;
    margin-left: 0px;
  }

  .boost {
    height: 365px;
    width: 390px;
    margin-top: -176px;
  }

  .boost-content {
    margin-top: -238px;
  }

  .boost-content p {
    font-size: 10px;
    width: 60%;
  }

  .boost-content h4 {
    font-size: 15px;
  }

  .ratingDiv {
    width: 400px;
    margin-top: 30px;
    height: 250px;
  }

  .p2-large {
    width: 400px;
    margin-top: 30px;
    margin-left: 10px;
    padding: 10px;
    height: 250px;
    padding-bottom: 0px;
  }

  .mobile {
    width: 100%;
    height: 265px;
  }

  .p2-large-b h4 {
    font-size: 14px;
  }

  .p2-large-b p {
    font-size: 10px;
  }

  .p-last {
    height: 1110px;
    width: 250px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin-left: -70px;
  }

  .tele-icon {
    margin-bottom: 50px;
  }

  .p-last h5 {
    color: white;
    margin-top: 20px;
    /* margin-bottom: 40px; */
  }

  .p-last p {
    text-align: center;
    font-size: 15px;
    padding: 10px 25px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .callnow {
    width: 130px;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    background-color: #0a0118;
    border: none;
    color: white;
    margin-top: 0px;
  }
}

@media screen and (max-width:767px) {
  .p1-large {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
  }

  .p1-large-a h4 {
    font-size: 15px;
  }

  .p1-large-a p {
    font-size: 12px;
  }

  .pmac {
    width: 300px;
    height: 200px;
    margin-left: 20px;
  }

  .boostDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
    height: 250px;
    margin-left: 0px;
  }

  .boost {
    height: 365px;
    width: 100%;
    margin-top: -176px;
  }

  .boost-content {
    margin-top: -238px;
  }

  .boost-content p {
    font-size: 13px;
    width: 60%;
  }

  .boost-content h4 {
    font-size: 20px;
  }

  .ratingDiv {
    width: 100%;
    margin-top: 30px;
    height: 250px;
  }

  .p2-large {
    width: 97%;
    margin-top: 30px;
    /* margin-left: 10px; */
    padding: 10px;
    height: 250px;
    padding-bottom: 0px;
  }

  .mobile {
    width: 100%;
    height: 265px;
  }

  .p2-large-b h4 {
    font-size: 14px;
  }

  .p2-large-b p {
    font-size: 10px;
  }

  .p-last {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 50px;
  }

  .tele-icon {
    margin-bottom: 0px;
  }

  .p-last h5 {
    color: white;
    margin-top: 20px;
    /* margin-bottom: 40px; */
  }

  .p-last p {
    text-align: center;
    font-size: 13px;
    padding: 10px 25px;
    margin-bottom: 30px;
    margin-top: 30px;
    width: 80%;
  }

  .callnow {
    width: 130px;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    background-color: #0a0118;
    border: none;
    color: white;
    margin-top: 0px;
  }
}

@media screen and (max-width:576px) {
  .p1-large {
    width: 100%;
    padding: 10px;
  }

  .p1-large-a h4 {
    font-size: 12px;
  }

  .p1-large-a p {
    font-size: 9px;
  }

  .pmac {
    width: 250px;
    height: 180px;
    margin-left: 20px;
  }

  .boostDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
    height: 250px;
    margin-left: 0px;
  }

  .boost {
    height: 365px;
    width: 100%;
    margin-top: -176px;
  }

  .boost-content {
    margin-top: -238px;
  }

  .boost-content p {
    font-size: 10px;
    width: 60%;
  }

  .boost-content h4 {
    font-size: 17px;
  }

  .ratingDiv {
    width: 100%;
    margin-top: 30px;
    height: 250px;
  }

  .p2-large {
    width: 97%;
    margin-top: 30px;
    /* margin-left: 10px; */
    padding: 10px;
    height: 250px;
    padding-bottom: 0px;
  }

  .mobile {
    width: 100%;
    height: 270px;
  }

  .p2-large-b h4 {
    font-size: 14px;
  }

  .p2-large-b p {
    font-size: 10px;
  }

  .p-last {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 50px;
  }

  .tele-icon {
    margin-bottom: 0px;
  }

  .p-last h5 {
    color: white;
    margin-top: 20px;
    /* margin-bottom: 40px; */
  }

  .p-last p {
    text-align: center;
    font-size: 13px;
    padding: 10px 25px;
    /* margin-bottom: 30px; */
    /* margin-top: 30px; */
    width: 80%;
  }

  .callnow {
    width: 130px;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    background-color: #0a0118;
    border: none;
    color: white;
    margin-top: 0px;
  }
}

@media screen and (max-width:575px) {
  .main-partnership-section {
    .partner-head {
      height: 150px;
    }

    padding: 25px 0px;
  }

  .p1-large {
    width: 100%;
    padding: 20px;
  }

  .p1-large-a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .p1-large-a h4 {
    font-size: 16px;
    text-align: center;
  }

  .p1-large-a p {
    font-size: 9px;
    width: 70%;
    text-align: center;
  }

  .mac-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pmac {
    width: 250px;
    height: 180px;
    margin-left: 100px;
  }

  .pbtn {
    font-size: 12px;
    padding: 6px 9px;
  }

  .boostDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
    height: 250px;
    margin-left: 0px;
  }

  .boost {
    height: 365px;
    width: 100%;
    margin-top: -176px;
  }

  .boost-content {
    margin-top: -238px;
  }

  .boost-content p {
    font-size: 10px;
    width: 60%;
  }

  .boost-content h4 {
    font-size: 17px;
  }

  .ratingDiv {
    width: 100%;
    margin-top: 30px;
    height: 250px;
  }

  .p2-large {
    width: 97%;
    margin-top: 30px;
    /* margin-left: 10px; */
    padding: 10px;
    height: 100%;
    padding-bottom: 0px;
  }

  .p2-large-b {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile {
    width: 80%;
    height: 270px;
  }

  .p2-large-b h4 {
    font-size: 18px;
  }

  .p2-large-b p {
    font-size: 11px;
    width: 80%;
    text-align: center;
    margin-top: 10px;
  }

  .pbtn2 {
    font-size: 11px;
  }

  .p-last {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 50px;
  }

  .tele-icon {
    margin-bottom: 0px;
  }

  .p-last h5 {
    color: white;
    margin-top: 20px;
    /* margin-bottom: 40px; */
  }

  .p-last p {
    text-align: center;
    font-size: 13px;
    padding: 10px 25px;
    /* margin-bottom: 30px; */
    /* margin-top: 30px; */
    width: 80%;
  }

  .callnow {
    width: 130px;
    padding: 6px 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    background-color: #0a0118;
    border: none;
    color: white;
    margin-top: 0px;
  }
}

@media screen and (max-width:434px) {
  .partner-head {
    height: 140px;
  }

  .main-partnership-section {
    padding: 20px 0px;
  }

  .p1-large {
    width: 100%;
    padding: 10px;
  }

  .p1-large-a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .p1-large-a h4 {
    font-size: 14px;
  }

  .p1-large-a p {
    font-size: 8px;
    width: 90%;
    text-align: center;
  }

  .mac-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pmac {
    width: 250px;
    height: 180px;
    margin-left: 100px;
  }

  .pbtn {
    font-size: 12px;
    padding: 6px 9px;
  }

  .boostDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
    height: 250px;
    margin-left: 0px;
  }

  .boost {
    height: 365px;
    width: 100%;
    margin-top: -176px;
  }

  .boost-content {
    margin-top: -238px;
  }

  .boost-content p {
    font-size: 11px;
    /* width: 60%; old */
    width: 85%;
  }

  .boost-content h4 {
    font-size: 14px;
    width: 49%;
    text-align: center;
  }

  .ratingDiv {
    width: 100%;
    margin-top: 30px;
    height: 250px;
  }

  .p2-large {
    width: 94%;
    margin-top: 30px;
    /* margin-left: 10px; */
    padding: 10px;
    height: 100%;
    padding-bottom: 0px;
  }

  .p2-large-b {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile {
    width: 80%;
    height: 230px;
  }

  .p2-large-b h4 {
    font-size: 15px;
  }

  .p2-large-b p {
    font-size: 8px;
    width: 90%;
    text-align: center;
    margin-top: 10px;
  }

  .pbtn2 {
    font-size: 11px;
  }

  .p-last {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top: 30px;
  }

  .tele-icon {
    margin-bottom: 0px;
  }

  .p-last h5 {
    color: white;
    margin-top: 10px;
    /* margin-bottom: 40px; */
  }

  .p-last p {
    text-align: center;
    font-size: 16px;
    text-align: center;
    padding: 0px 25px;
    /* margin-bottom: 30px; */
    /* margin-top: 30px; */
    width: 90%;
  }

  .callnow {
    width: 120px;
    padding: 6px 15px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 30px;
    background-color: #0a0118;
    border: none;
    color: white;
    margin-top: 0px;
  }
}