.teamcard-section {
  height: auto;
  width: 100%;
  background-color: #0a0118;
  padding-top: 80px;
}

.tcontent-h1 {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: end;

}

.tcontent-p {
  color: darkgray;
  position: absolute;
  right: 0px;
  padding-top: 15px;
  border-left: 1px solid rgb(51, 48, 48);
  padding-left: 30px;
  font-size: 18px;
}

.teamimg {
  width: 260px;
  height: 270px;
  margin-bottom: 20px;
  background-color: #0a0118;
  opacity: 0.4;
}

.teamimg:hover {
  opacity: 1;
}

.social-icon {
  display: flex;
  flex-direction: row;
}

.card-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  padding: 40px 20px;
}

.card {
  background-color: transparent;
  background-image: linear-gradient(180deg, #2b2338 0%, #0a0118 100%);
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 30px 10px;
  margin-right: 10px;
}

.card:hover {
  border: 1px solid #FF7FC2;
}

.card h4 {
  color: #FF7FC2;
}

.card h6 {
  color: white;
  font-weight: 600;
}

.social-media-icon {
  background-color: white;
}

.social-media-icon:hover {
  background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
}

@media screen and (max-width:1208px) {
  .card {
    margin-right: 10px;
  }

  .card-section {
    padding: 40px 0px;
  }
}

@media screen and (max-width:1167px) {
  .card {
    margin-left: 10px;
  }

  .cardrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .card-section {
    padding: 40px 10px;
  }

  .teamimg {
    width: 200px;
    height: 210px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width:987px) {
  .card-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .card {
    margin-left: 40px;
    margin-top: 20px;
  }

  .teamimg {
    width: 260px;
    height: 270px;
    margin-bottom: 40px;
  }

}

@media screen and (max-width:683px) {
  .card-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .card {
    margin-left: 20px;
    margin-top: 20px;
  }

  .teamimg {
    width: 200px;
    height: 210px;
    margin-bottom: 40px;
  }

  .card h4 {
    font-size: 18px;
  }

  .card h6 {
    font-size: 13px;
    margin-bottom: 10px;
  }

}

@media screen and (max-width:715px) {
  .tcontent-p p {
    font-size: 10px;
  }

  .tcontent-h1 h1 {
    font-size: 18px;
    line-height: 25px;
    padding-top: 20px;
  }
}

@media screen and (max-width:576px) {
  .tcontent-p p {
    font-size: 10px;
  }

  .tcontent-h1 h1 {
    font-size: 18px;
    line-height: 25px;
    padding-top: 20px;
  }

  .tcontent-h1 {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }

  .tcontent-p {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
}

@media screen and (max-width:575px) {
  .tcontent-p p {
    font-size: 15px;
    text-align: center;
    margin-top: 60px;
    width: 100%;
    line-height: 22px;

  }

  .tcontent-h1 h1 {
    font-size: 30px;
    line-height: 20px;
    padding-top: 20px;
    text-align: left;
  }

  .tcontent-h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tcontent-p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width:523px) {
  .card-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .card {
    margin-left: 0px;
    margin-top: 20px;
    padding: 30px 20px !important;
  }

  .teamimg {
    width: 310px;
    height: 280px;
    margin-bottom: 40px;
  }

  .card h4 {
    font-size: 18px;
  }

  .card h6 {
    font-size: 13px;
    margin-bottom: 10px;
  }

}

@media screen and (max-width:369px) {
  .card-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .card {
    margin-left: 0px;
    margin-top: 20px;
    padding: 30px 20px !important;
  }

  .teamimg {
    width: 220px;
    height: 230px;
    margin-bottom: 40px;
  }

  .card h4 {
    font-size: 18px;
  }

  .card h6 {
    font-size: 13px;
    margin-bottom: 10px;
  }

}

@media screen and (max-width:290px) {
  .card-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .card {
    margin-left: 0px;
    margin-top: 20px;
    padding: 30px 10px !important;
  }

  .teamimg {
    width: 190px;
    height: 200px;
    margin-bottom: 40px;
  }

  .card h4 {
    font-size: 18px;
  }

  .card h6 {
    font-size: 13px;
    margin-bottom: 10px;
  }

}