.home_page {
  height: auto;
  width: 100%;
  background-color: #0a0118;
}

.mainHero {
  height: 80vh;
  width: 100%;
}

#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.heroDiv {
  height: 100%;
  width: 100%;
  color: #f1f1f1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(15, 5, 32, 0.8);
}

.slogan {
  border-radius: 30px;
  border: 2px solid #f775e5;
  padding: 5px 12px;
  font-size: 15px;
  font-weight: 750;
  letter-spacing: 0.2px;
  margin-bottom: 28px;
  opacity: 0.7;
  margin-top: 30px;
}

.heroDiv h1 {
  text-align: center;
  font-size: 60px;
  font-weight: 800;
  letter-spacing: 0.0px;
  line-height: 0.9;
  margin-bottom: 28px;
  padding-top: 30px;
}

.heroDiv p {
  text-align: center;
  font-size: 18px;
  width: 50%;
  font-weight: 400;
  letter-spacing: 0.5;
  color: darkgray;
  margin-bottom: 30px;
}



/* Testimonial */
.testimonial {
  margin-top: 30px;
}

/* project */
.project {
  margin-top: 80px;
  height: auto;
}

.project-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  padding: 30px;
}

.project-content h6 {
  font-weight: 700;
  font-size: 14px;
  color: #f775e5;
  padding-bottom: 15px;

}

.project-content h1 {
  color: white;
  font-weight: 750;
  font-size: 43px;
  width: 100%;
  text-align: center;
}

.counter {
  height: auto;
  padding-top: 60px;
  background-color: rgba(15, 5, 32, 0.8);
}

.technology {
  background-color: #0f0520;
  height: auto;
  width: 100%;
  padding-bottom: 50px;
}

.technology-content {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  background: url(../../Assets/lineargradientImg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.technology-content h6 {
  font-weight: 700;
  font-size: 15px;
  color: #f775e5;
  padding-bottom: 15px;

}

.technology-content h1 {
  color: white;
  font-weight: 750;
  font-size: 43px;
  width: 55%;
  text-align: center;
}

/* media queries */
@media screen and (max-width:990px) {
  .heroDiv h1 {
    font-size: 40px;
    font-weight: 700;
  }

  .heroDiv p {
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  .heroDiv {
    padding-top: 70px;
  }
}

@media screen and (max-width:694px) {
  .mainHero {
    height: 110vh;
  }
}

@media screen and (max-width:634px) {
  .heroDiv p {
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .heroDiv {
    padding-top: 90px;
  }
}

@media screen and (max-width:568px) {
  .heroDiv h1 {
    font-size: 32px;
    font-weight: 700;
  }

  .heroDiv p {
    font-size: 13px;
    letter-spacing: 0.5px;
    width: 80%;
  }

  .heroDiv {
    padding-top: 100px;
  }

  .slogan {
    padding: 3px 8px;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin-bottom: 14px;
    margin-top: 41px;
  }

  .mainHero {
    height: 110vh;
  }

  #myVideo {
    min-height: 100%;
  }
}

@media screen and (max-width:414px) {
  #myVideo {
    min-height: 150%;
  }
}

@media screen and (max-width:404px) {
  .heroDiv h1 {
    font-size: 25px;
    font-weight: 650;
  }

  .heroDiv p {
    font-size: 10px;
    width: 100%;
  }

  .heroDiv {
    padding-top: 60px;
  }

  .mainHero {
    height: 110vh;
  }

  .slogan {
    padding: 3px 8px;
    font-size: 9px;
    font-weight: 500;
    letter-spacing: 0.4px;
    margin-bottom: 14px;
    margin-top: 41px;
  }
}

@media screen and (max-width:375px) {
  #myVideo {
    min-height: 140%;
  }
}

@media screen and (max-width:333px) {
  .heroDiv h1 {
    font-size: 20px;
    font-weight: 600;
  }

  .heroDiv p {
    font-size: 8px;
    width: 100%;
  }

  .heroDiv {
    padding-top: 60px;
  }

  .slogan {
    padding: 2px 7px;
    font-size: 8px;
    font-weight: 400;
    letter-spacing: 0.4px;
    margin-bottom: 14px;
    margin-top: 41px;
  }
}

/* technology section media queries */
@media screen and (max-width: 1169px) {
  .technology-content h1 {
    width: 65%;
    font-size: 30px;
  }
}

@media screen and (max-width: 621px) {
  .technology-content h1 {
    width: 65%;
    font-size: 22px;
  }
}

@media screen and (max-width: 462px) {
  .technology-content h1 {
    width: 75%;
    font-size: 19px;
    font-weight: 550;
  }
}

@media screen and (max-width: 340px) {
  .technology-content h1 {
    width: 85%;
    font-size: 15px;
  }
}

/* project media query */
@media screen and (max-width: 718px) {
  .project-content h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 418px) {
  .project-content h1 {
    font-size: 20px;
  }
}
@media screen and (max-width: 347px) {
  .project-content h1 {
    font-size: 17px;
  }
}