.development-future {
  padding: 20px;
  padding-top: 60px;
  height: 550px;
  width: 100%;
  margin-bottom: 50px;
  border-bottom: 1px solid rgb(97, 94, 94);
  border-radius: 110px;
  padding-bottom: -100px;
}

.development-content {
  padding: 70px;
  margin-top: 30px;
  background-image: url(../../Assets/centerLineargradient.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.4px;
}

.development-content h6 {
  color: #ff7fc2;
  padding: 5px 12px;
  font-size: 15px;
  font-weight: 750;
  letter-spacing: 0.2px;
  margin-bottom: 28px;
  opacity: 0.7;

}

.development-content h1 {
  color: white;
  text-align: left;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0.0px;
  line-height: 45px;
  margin-bottom: 28px;
}

.development-content p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5;
  color: rgb(204, 199, 199);
  text-align: justify;
  line-height: 25px;
  word-spacing: 0.0px;
}

.web-Img {
  width: 100%;
  height: 520px;
  /* margin-right: 30px; */
  margin-top: 40px;
  overflow: hidden;
  padding: 30px;
}

.web-Img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width:1217px) {
  .development-future {
    height: 520px;
  }

  .development-content h1 {
    font-size: 30px;
    line-height: 38px;
  }

  .development-content p {
    font-size: 14px;
    line-height: 21px;
  }

  .web-Img {
    height: 500px;
  }
}

@media screen and (max-width:1035px) {
  .development-future {
    height: 490px;
  }

  .development-content h1 {
    font-size: 25px;
    line-height: 30px;
  }

  .development-content p {
    font-size: 14px;
    width: 100%;
    line-height: 21px;
  }

  .web-Img {
    height: 500px;
  }
}

@media screen and (max-width:955px) {
  .development-future {
    height: 450px;
    padding-top: 40px;
  }

  .development-content {
    padding: 70px 30px;
    padding-bottom: 20px;
  }

  .development-content h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .development-content p {
    font-size: 15px;
    width: 100%;
    line-height: 22px;
  }

  .web-Img {
    padding-top: 50px;
  }

  .web-Img img {
    height: 330px;
    object-fit: cover;
  }
}

@media screen and (max-width:839px) {
  .development-future {
    height: 430px;
    padding-top: 50px;
  }

  .development-content {
    padding: 90px 20px 30px 30px;
  }

  .development-content h6 {
    margin-bottom: 18px;
  }

  .development-content h1 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .development-content p {
    font-size: 14px;
    width: 100%;
    line-height: 19px;
  }

  .web-Img {
    padding-top: 50px;
  }

  .web-Img img {
    height: 300px;
    object-fit: cover;
  }
}

@media screen and (max-width:767px) {
  .development-future {
    height: 740px;
    padding-top: 30px;
  }

  .development-content {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .development-content h1 {
    font-size: 30px;
    width: 60%;
    text-align: center;
    margin-bottom: 20px;
    line-height: 37px;
    letter-spacing: 0.4px;
  }

  .development-content p {
    font-size: 16px;
    text-align: center;
    width: 80%;
    line-height: 22px;
    margin-top: 15px;
  }

  .web-Img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px;
    padding: 0px;
    height: 340px;
  }

  .web-Img img {
    object-fit: contain;
  }
}

@media screen and (max-width:767px) {
  .development-content h1 {
    font-size: 25px;
    line-height: 30px;
  }
}

@media screen and (max-width:632px) {
  .development-content h1 {
    font-size: 22px;
    line-height: 30px;
  }
}

@media screen and (max-width:567px) {
  .development-content h1 {
    font-size: 22px;
    line-height: 30px;
    width: 85%;
  }
}

@media screen and (max-width:480px) {
  .development-content {
    background-image: url(../../Assets/centerLineargradient.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .development-content h1 {
    font-size: 22px;
    line-height: 30px;
    width: 85%;
  }

  .development-future {
    height: 750px;
    border-radius: 60px;

  }

  .development-content p {
    font-size: 13px;
    line-height: 18px;
  }
}

@media screen and (max-width:421px) {
  .development-content h1 {
    font-size: 19px;
    line-height: 25px;
    width: 85%;
  }

  .development-future {
    height: 740px;
    border-radius: 60px;
  }

  .development-content p {
    font-size: 12px;
    line-height: 18px;
    margin-top: 10px;
  }
}

@media screen and (max-width:375px) {
  .development-content h1 {
    font-size: 15px;
    line-height: 20px;
    width: 85%;
  }

  .development-future {
    height: 700px;
    border-radius: 60px;
  }

  .development-content p {
    font-size: 11px;
    line-height: 16px;
    margin-top: 5px;
  }
}