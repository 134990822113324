.accor-head,
.accordion-button {
  background: #0a0118 !important;
  color: white !important;
  height: 70px !important;
  display: flex;
  align-items: center;
  border: none !important;
  box-shadow: none !important;
  border: 1px solid #312546 !important;
  border-radius: 10px 10px 0 0 !important;
}

.accordion-item {
  margin-top: 20px;
  border: none;
  border-radius: 10px 10px 0 0 !important;
}

.accor-body {
  background-color: #0c021d;
  border-radius: 0 !important;
  color: #bdbaba;
  border: 1px solid #312546 !important;

}

.accordian {
  border-radius: 20px;
}

.accordion-button:not(.collapsed) {
  background-image: linear-gradient(180deg, #2b2338 0%, #0a0118 100%) !important;
  color: white !important;
}

.accordion-button:not(.collapsed)+.accordion-collapse .accordion-body {
  background-color: rgba(15, 5, 32, 0.8) !important;
  color: white !important;
}