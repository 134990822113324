.detailservice_page {
  width: 100%;
  height: auto;
  background-color: #0a0118;
}

.description {
  margin-top: 50px;
}

.pricing-section {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 90px;
  overflow: hidden;
}

.pricing-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
}

.pricing-card-content p {
  color: #ff7fc2;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1.5px;
}

.pricing-card-content h1 {
  color: white;
  font-size: 37px;
  font-weight: 600;
  width: 55%;
  text-align: center;
  margin-bottom: 20px;
}

.pricing-card-content h5 {
  font-size: 17px;
  color: rgb(209, 202, 202);
  font-weight: 500;
  margin-top: 20px;
}

/* media queries */
@media screen and (max-width:1167px) {
  .pricing-card-content {
    padding-top: 0px;
  }

  .pricing-card-content h1 {
    font-size: 34px;
    width: 55%;
  }

  .pricing-card-content h5 {
    font-size: 14px;
    margin-top: 5px;
  }
}

@media screen and (max-width:929px) {
  .pricing-card-content {
    padding-top: 0px;
  }

  .pricing-card-content h1 {
    font-size: 30px;
    width: 55%;
  }

  .pricing-card-content h5 {
    font-size: 12px;
    margin-top: 5px;
  }
}

@media screen and (max-width:819px) {
  .pricing-card-content {
    padding-top: 0px;
  }

  .pricing-card-content h1 {
    font-size: 26px;
    width: 55%;
  }

  .pricing-card-content p {
    font-size: 15px;
  }

  .pricing-card-content h5 {
    font-size: 13px;
    margin-top: 5px;
  }
}

@media screen and (max-width:709px) {
  .pricing-card-content {
    padding-top: 0px;
  }

  .pricing-card-content h1 {
    font-size: 22px;
    width: 65%;
  }

  .pricing-card-content p {
    font-size: 14px;
  }

  .pricing-card-content h5 {
    font-size: 13px;
    margin-top: 5px;
  }
}

@media screen and (max-width:508px) {
  .pricing-card-content {
    padding-top: 0px;
  }

  .pricing-card-content h1 {
    font-size: 18px;
    width: 65%;
  }

  .pricing-card-content p {
    font-size: 13px;
  }

  .pricing-card-content h5 {
    font-size: 13px;
    margin-top: 5px;
    width: 50%;
    text-align: center;
  }
}
@media screen and (max-width:416px) {
  .pricing-card-content {
    padding-top: 0px;
  }

  .pricing-card-content h1 {
    font-size: 18px;
    width: 75%;
  }

  .pricing-card-content p {
    font-size: 13px;
  }

  .pricing-card-content h5 {
    font-size: 12px;
    margin-top: 5px;
    width: 70%;
    text-align: center;
  }
}
@media screen and (max-width:361px) {
  .pricing-card-content {
    padding-top: 0px;
  }

  .pricing-card-content h1 {
    font-size: 18px;
    width: 85%;
  }

  .pricing-card-content p {
    font-size: 13px;
  }

  .pricing-card-content h5 {
    font-size: 10px;
    margin-top: 5px;
    width: 80%;
    text-align: center;
    margin-top: 0px;
  }
}
