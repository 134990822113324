/* value section */
.pricing-sec {
  height: 600px;
  width: 100%;
  background-color: #0a0118;
  margin-top: 90px;
}

.pricing-first-content {
  padding-top: 50px;
  padding-left: 40px;
  padding-right: 20px !important;
}

.pricing-first-content h1 {
  color: rgb(250, 242, 242);
  text-align: left;
  font-size: 40px;
  font-weight: 650;
  letter-spacing: 0.0px;
  line-height: 45px;
}

.pricing-first-content p {
  color: #9B96B0;
  font-size: 18px;
  line-height: 25px;
  width: 89%;
  padding: 30px 0px;
  text-align: left;
  border-bottom: 1.5px solid rgb(49, 48, 48);
}

.pricing-second-border {
  border: 1.5px solid rgb(63, 61, 61);
  padding: 10px;
  height: 540px;
  width: 46%;
  border-radius: 15px;
  position: absolute;
  overflow: hidden;
}

.pricing-second {
  height: 100%;
  width: 100%;
  background-image: url(https://theme.creativemox.com/digimax/wp-content/uploads/sites/7/2024/01/computer-screens-with-data.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5px;
  border-radius: 10px;
  filter: brightness(35%);
  /* position: absolute; */
  object-fit: cover;
}

.pricing-second:hover {
  filter: none;
}

.value-arrow-top {
  height: auto;
  position: absolute;
  right: 0px;
  background-color: transparent;
  background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
  border-radius: 50%;
  padding: 20px 22px;
  margin-right: 30px;
}

.right-up-value {
  color: white;
  font-weight: bolder;
  font-size: small;
}

.right-value {
  left: 0px;
  position: absolute;
  top: 0px;
}

.right {
  color: #9B96B0;
  margin-left: 5px;
  /* background-color: #9B96B0; */
}

.check {
  height: 17px;
  width: 17px;
  margin-right: 5px;
}

.check-content {
  color: #9B96B0;
  font-size: 16px;
  margin-top: 25px;
}

.advantages-list {
  padding-bottom: 30px;
  border-bottom: 1.5px solid rgb(49, 48, 48);
  width: 89%;
}

.advantage-1 {
  margin-top: 10px;
}

.advantage-2 {
  margin-top: 10px;
}

@media screen and (max-width: 1279px) {
  .pricing-sec {
    height: 600px;
  }

  .pricing-first-content h1 {
    font-size: 30px;
    line-height: 38px;
  }

  .pricing-first-content p {
    font-size: 16px;
  }

  .check-content {
    font-size: 15px;
  }
}

@media screen and (max-width: 1166px) {
  .pricing-first-content p {
    font-size: 15px;
  }

  .check-content {
    font-size: 13px;
  }
}

@media screen and (max-width: 1052px) {
  .pricing-first-content h1 {
    font-size: 25px;
    line-height: 38px;
  }

  .pricing-first-content p {
    font-size: 13px;
  }

  .check-content {
    font-size: 11px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 991px) {
  .pricing-sec {
    height: 1000px;
  }

  .pricing-first-content h1 {
    font-size: 30px;
    line-height: 38px;
    text-align: center;
  }

  .pricing-first-content p {
    font-size: 13px;
    text-align: center;
  }

  .advantages-list {
    padding-left: 60px;
  }

  .check-content {
    font-size: 14px;
  }

  .pricing-second-border {
    width: 95%;
  }

  .value-arrow-top {
    margin-right: 15px;
  }

  .pricing-first-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .pricing-second-border {
    height: 450px;
  }
}

@media screen and (max-width: 655px) {
  .pricing-sec {
    height: 930px;
    margin-top: 40px;
  }

  .pricing-first-content h1 {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
  }

  .pricing-first-content p {
    font-size: 11px;
    text-align: center;
  }

  .check-content {
    font-size: 12px;
  }

  .pricing-second-border {
    width: 93%;
  }

  .value-arrow-top {
    margin-right: 20px;
  }

  .pricing-first-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .pricing-second-border {
    height: 400px;
  }
}

@media screen and (max-width: 590px) {
  .pricing-sec {
    height: 860px;
    margin-top: 30px;
  }

  .pricing-first-content h1 {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }

  .pricing-first-content p {
    font-size: 9px;
    text-align: center;
    line-height: 15px;
  }

  .advantages-list {
    padding-left: 20px;
  }

  .check-content {
    font-size: 12px;
    margin-top: 15px;
  }

  .pricing-second-border {
    width: 93%;
  }

  .value-arrow-top {
    margin-right: 20px;
  }

  .pricing-first-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .pricing-second-border {
    height: 350px;
  }
}

@media screen and (max-width: 575px) {
  .pricing-sec {
    height: 950px;
    margin-top: -110px;
  }

  .pricing-first-content h1 {
    font-size: 23px;
    line-height: 32px;
    text-align: center;
  }

  .pricing-first-content p {
    font-size: 11px;
    text-align: center;
    line-height: 15px;
  }

  .advantages-list {
    padding-left: 100px;
  }

  .check-content {
    font-size: 16px;
    margin-top: 15px;
  }

  .pricing-second-border {
    width: 93%;
  }

  .value-arrow-top {
    margin-right: 20px;
  }

  .pricing-first-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .pricing-second-border {
    height: 300px;
  }
}

@media screen and (max-width: 525px) {
  .pricing-sec {
    height: 890px;
    margin-top: -100px;
  }

  .pricing-first-content h1 {
    font-size: 23px;
    line-height: 28px;
    text-align: center;
  }

  .pricing-first-content p {
    font-size: 11px;
    text-align: center;
    line-height: 16px;
    padding: 10px 0px;
  }

  .advantages-list {
    padding-left: 70px;
  }

  .check-content {
    font-size: 15px;
    width: 100%;
    margin-top: 5px;
  }

  .pricing-second-border {
    width: 90%;
  }

  .value-arrow-top {
    margin-right: 20px;
    padding: 10px 11px;
  }

  .pricing-first-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .pricing-second-border {
    height: 300px;
  }
}

@media screen and (max-width: 450px) {
  .pricing-sec {
    height: 880px;
    margin-top: -100px;
  }

  .pricing-first-content h1 {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }

  .pricing-first-content p {
    font-size: 9px;
    text-align: center;
    line-height: 16px;
    padding: 10px 0px;
  }

  .advantages-list {
    padding-left: 50px;
  }

  .check-content {
    font-size: 13px;
    width: 100%;
    margin-top: 5px;
  }

  .pricing-second-border {
    width: 90%;
  }

  .value-arrow-top {
    margin-right: 20px;
    padding: 10px 11px;
  }

  .pricing-first-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .pricing-second-border {
    height: 300px;
  }

  .advantages-list {
    border-bottom: none;
  }
}

@media screen and (max-width: 390px) {
  .pricing-sec {
    height: 830px;
    margin-top: -120px;
  }

  .pricing-first-content h1 {
    font-size: 17px;
    line-height: 22px;
    text-align: center;
  }

  .pricing-first-content p {
    font-size: 8px;
    text-align: center;
    line-height: 16px;
    padding: 10px 0px;
  }

  .advantages-list {
    padding-left: 40px;
  }

  .check-content {
    font-size: 12px;
    width: 100%;
    margin-top: 5px;
  }

  .pricing-second-border {
    width: 87%;
  }

  .value-arrow-top {
    margin-right: 20px;
    padding: 10px 11px;
  }

  .pricing-first-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .pricing-second-border {
    height: 250px;
  }
}

@media screen and (max-width: 346px) {
  .pricing-sec {
    height: 800px;
    margin-top: -120px;
  }

  .pricing-first-content h1 {
    font-size: 17px;
    line-height: 22px;
    text-align: center;
  }

  .pricing-first-content p {
    font-size: 8px;
    text-align: center;
    line-height: 16px;
    padding: 10px 0px;
  }

  .advantages-list {
    padding-left: 20px;
  }

  .check-content {
    font-size: 10px;
    width: 100%;
    margin-top: 5px;
  }

  .pricing-second-border {
    width: 84%;
  }

  .value-arrow-top {
    margin-right: 29px;
    padding: 7px 9px;
  }

  .pricing-first-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .pricing-second-border {
    height: 210px;
  }
}

@media screen and (max-width: 343px) {
  .pricing-sec {
    height: 800px;
    margin-top: 0px;
  }

  .pricing-first-content h1 {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }

  .pricing-first-content p {
    font-size: 7px;
    text-align: center;
    line-height: 16px;
    padding: 10px 0px;
  }

  .advantages-list {
    padding-left: 30px;
  }

  .check-content {
    font-size: 10px;
    width: 100%;
    margin-top: 5px;
  }

  .pricing-second-border {
    width: 84%;
  }

  .value-arrow-top {
    margin-right: 29px;
    padding: 7px 9px;
  }

  .pricing-first-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .pricing-second-border {
    height: 210px;
  }
}