.first-section {
  background-image: url(../../Assets/centerLineargradient.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  background-color: #0a0118;
  height: 510px;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 70px;
  border-bottom: 1px solid rgb(97, 94, 94);
  border-radius: 110px;
}

.first-section h1 {
  color: white;
  font-size: 47px;
  font-weight: 500;
}

.first-section h5 {
  color: white;
  font-size: 25px;
  font-weight: 380;
  width: 50%;
  text-align: center;
  margin-top: 20px;
  line-height: 40px;
}

/* media queries */
@media screen and (max-width: 1144px) {
  .first-section h5 {
    font-size: 20px;
    width: 70%;
    line-height: 30px;
  }

  .first-section {
    height: auto;
    padding: 120px 0px;
  }

  .first-section h1 {
    font-size: 40px;
    margin-top: 40px;
  }
}

@media screen and (max-width: 654px) {
  .first-section h5 {
    font-size: 17px;
    width: 70%;
    line-height: 22px;

  }

  .first-section {
    height: auto;
    padding: 120px 0px;
  }

  .first-section h1 {
    font-size: 35px;
    margin-top: 50px;
    line-height: 25px;

  }
}

@media screen and (max-width: 547px) {
  .first-section h5 {
    font-size: 15px;
    width: 75%;
    line-height: 20px;

  }

  .first-section {
    height: auto;
    padding: 100px 0px;
    margin-bottom: 130px;
  }

  .first-section h1 {
    font-size: 35px;
    margin-top: 70px;
    line-height: 25px;

  }
}

@media screen and (max-width: 457px) {
  .first-section h5 {
    font-size: 12px;
    width: 85%;
    line-height: 16px;
  }

  .first-section {
    height: auto;
    padding: 80px 0px;
    margin-bottom: 130px;
    border-radius: 70px;
  }

  .first-section h1 {
    font-size: 30px;
    margin-top: 80px;
    line-height: 25px;

  }
}

@media screen and (max-width: 343px) {
  .first-section h5 {
    font-size: 12px;
    width: 90%;
    line-height: 16px;
  }

  .first-section {
    height: auto;
    padding: 50px 0px;
    margin-bottom: 0px;
    border-radius: 50px;
  }

  .first-section h1 {
    font-size: 30px;
    margin-top: 80px;
    line-height: 25px;

  }
}