.faq_page {
  height: auto;
  width: 100%;
  background-color: #0a0118;
  padding: 0px;
  margin: 0px;
}

.faq-section {
  margin-top: 50px;
  height: auto;
  width: 100%;
}

.faq-question {
  height: auto;
  margin-top: 40px;
  padding-bottom: 60px;
}

.faq-content {
  padding: 30px 20px;
  margin-top: 30px;
}

.faq-content h6 {
  font-size: 15px;
  color: #ff7fc2;
}

.faq-content h1 {
  font-size: 30px;
  color: white;
  margin-top: 25px;
  line-height: 39px;
  width: 100%;
}

.faqbtn {
  width: auto;
  padding: 7px 30px;
  height: auto;
  background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
  border-radius: 20px;
  border: none;
  color: white;
  font-size: 12px;
  font-weight: 500;
  margin-top: 30px;
}

@media screen and (max-width: 1171px) {
  .faq-question{
    height: auto;
  }
  .faq-content h1 {
    font-size: 23px;
    margin-top: 15px;
  }

  .faq-content h6 {
    font-size: 14px;
  }

  .faqbtn {
    margin-top: 25px;
  }
}

@media screen and (max-width: 991px) {
  .faq-question {
    height: auto;
    padding: 0px 80px;
    padding-bottom: 50px;
  }

  .faq-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .faq-content h1 {
    font-size: 34px;
    margin-top: 15px;
    text-align: center;
  }

  .faq-content h6 {
    font-size: 16px;
  }

  .faqbtn {
    margin-top: 25px;
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .faq-question {
    height: auto;
    padding: 0px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 567px) {
  .faq-section {
    margin-top: 10px;
  }

  .faq-question {
    height: auto;
    padding: 0px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 435px) {
  .faq-content h1 {
    font-size: 22px;
  }
}