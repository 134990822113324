.digital-future {
  padding: 20px;
  margin-top: 150px;
  height: auto;
  width: 100%;
  margin-bottom: 50px;

}

.digitalImgDiv {
  border: 1px solid #39383b;
  border-radius: 20px;
  height: auto;
  padding: 15px;
  /* margin: 20px; */
  position: relative;
  background: rgba(15, 5, 32, 0.8);
  overflow: hidden;
}

.circle {
  height: 115px;
  width: 115px;
  border-radius: 50%;
  background-color: #b47cfd;
  border: 1px solid white;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.innerCircle {
  color: #b47cfd;
  border: 1px solid white;
}

.circleText {
  color: white;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.digitalImg {
  height: auto;
  width: 100%;
  border-radius: 20px;
  opacity: 0.5px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);

}

.digitalImg.hovered:hover {
  filter: none;
  background-color: transparent;
}

.digital-content {
  padding: 70px;
  margin-top: 30px;
}

.digital-content h6 {
  color: #ff7fc2;
  padding: 5px 12px;
  font-size: 15px;
  font-weight: 750;
  letter-spacing: 0.2px;
  margin-bottom: 28px;
  opacity: 0.7;

}

.digital-content h1 {
  color: white;
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.0px;
  line-height: 45px;
  margin-bottom: 28px;
}

.digital-content p {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.5;
  color: darkgray;
  text-align: justify;
  line-height: 23px;
  word-spacing: 0.0px;
}

/* media queries */
@media screen and (max-width:1286px) {
  .digital-content h1 {
    font-size: 35px;
    line-height: 40px;
  }

  .digital-content p {
    font-size: 17px;
  }
}

@media screen and (max-width:1167px) {
  .digital-content h1 {
    font-size: 32px;
    line-height: 39px;
  }

  .digital-content p {
    font-size: 17px;
  }
}

@media screen and (max-width:1096px) {
  .digital-content h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .digital-content p {
    font-size: 15px;
  }

  .digital-content {
    margin-top: 0px;
    padding-top: 50px;
  }
}

@media screen and (max-width:1002px) {
  .digital-content h1 {
    font-size: 26px;
    line-height: 36px;
  }

  .digital-content p {
    font-size: 16px;
  }
}

@media screen and (max-width:991px) {
  .digital-future {
    margin-bottom: 20px;
  }

  .digital-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .digital-content h1 {
    font-size: 40px;
    line-height: 45px;
    text-align: center;
    width: 80%;
  }

  .digital-content p {
    font-size: 18px;
    width: 80%;
    text-align: center;
    line-height: 25px;
  }
}

@media screen and (max-width:769px) {
  .digital-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .digital-content h1 {
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    width: 80%;
  }

  .digital-content p {
    font-size: 17px;
    width: 90%;
    text-align: center;
    line-height: 25px;
  }

  .digital-future {
    margin-bottom: 0px;
  }
}

@media screen and (max-width:694px) {
  .digital-future {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .digital-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 25px;
  }

  .digital-content h1 {
    font-size: 35px;
    line-height: 40px;
    text-align: center;
    width: 90%;
  }

  .digital-content p {
    font-size: 17px;
    width: 100%;
    text-align: center;
    line-height: 25px;
  }
}

@media screen and (max-width:547px) {
  .digital-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 10px;
  }

  .circle {
    height: 80px;
    width: 80px;
  }

  .digital-content h1 {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    width: 100%;
    font-weight: 550;
  }

  .digital-content p {
    font-size: 16px;
    width: 100%;
    text-align: center;
    line-height: 20px;
  }

  .digital-future {
    margin-bottom: 0px;
    margin-top: -100px;
  }
}

@media screen and (max-width:412px) {
  .digital-future {
    margin-bottom: 0px;
    /* margin-top: -50px; */
  }

  .digital-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 10px;
  }

  .digital-content h1 {
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    width: 100%;
    font-weight: 500;
  }

  .digital-content p {
    font-size: 15px;
    width: 100%;
    text-align: center;
    line-height: 20px;
  }
}

@media screen and (max-width:343px) {
  .digital-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 5px;
  }

  .digital-content h1 {
    font-size: 21px;
    line-height: 28px;
    text-align: center;
    width: 100%;
    font-weight: 500;
  }

  .digital-content p {
    font-size: 13px;
    width: 100%;
    text-align: center;
    line-height: 17px;
  }

  .digital-future {
    margin-bottom: 0px;
    margin-top: 50px;
  }
}