* {
  padding: 0px;
  margin: 0px;
}

.footer-section {
  height: auto;
  padding: 50px;
  padding-top: 70px;
  color: darkgray;
  border-top: 1px solid rgb(63, 62, 60);
  gap: 0px;
  opacity: 0.9px;
  background: #0a0118;
  font-family: sans-serif;
}

.footer-col1 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.footer-col2{
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
}
.footer-col1 img {
  width: 160px !important;
  height: 120px !important;
}

.footer-col1 p {
  margin-bottom: 30px;
  font-weight: 300;
  color: darkgray;
  font-family: sans-serif;
  letter-spacing: 0.7;
  width: 70%;

}

.footer-col2 h6 {
  color: white;
  font-size: 20px;
  font-weight: 300;
  line-height: 28.32px;
  letter-spacing: 0.04em;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding-left: 30px;
  margin-bottom: 20px;
}

.footer-col3 h6 {
  color: white;
  font-size: 20px;
  font-weight: 300;
  line-height: 28.32px;
  letter-spacing: 0.04em;
  margin-bottom: 20px;

}

.footer-col4 h6 {
  color: white;
  font-size: 20px;
  font-weight: 300;
  line-height: 28.32px;
  letter-spacing: 0.04em;
  margin-bottom: 20px;

}

.footer-li {
  font-size: 13px;
  line-height: 31.24px;
  letter-spacing: 0.06px;
  text-align: center !important;
  color: darkgray;
  text-decoration: none;
}

.footer-li:hover {
  color: #f775e5;
}

hr {
  border: 1.8px solid rgb(55, 56, 58);
}

.line {
  height: 50px;
  width: 100%;
  margin-top: 50px;
}

.social-media {
  display: flex;
  flex-direction: row;
}

.social-media-icon {
  width: 38px;
  height: 35px;
  border-radius: 50%;
  color: rgb(244, 246, 250);
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .footer-section {
    padding: 30px;
  }
}

@media screen and (min-width:1300px) and (max-width:1700px) {
  .footer-col1 p {
    font-size: 17px;
    font-weight: 100;
  }
}

@media screen and (min-width:900px) {
  .last-sec div div {
    font-size: 14px;
  }
}

@media screen and (max-width:991px) {
  .social-media{
    margin-bottom: 30px;
  }
  .footer-section {
    padding: 40px 0px;
  }

  .footer-col1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-col1 p {
    margin-bottom: 17px;
    font-size: 12px;
    font-weight: 100;
    line-height: 19px;
    color: darkgray;
    letter-spacing: 0.6px;
    text-align: center;
    width: 60%;
  }

  .social-media {
    display: flex;
    flex-direction: row;
  }

  .social-media-icon {
    width: 38px;
    height: 35px;
    border-radius: 50%;
    color: rgb(244, 246, 250);
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 575px) {
  .footer-col2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }

  .footer-section {
    padding: 20px 0px;
  }

  .last-sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .last-a {
    text-align: center;
  }

  .last-b {
    text-align: center;
  }

  .last-c {
    text-align: center;
  }
}