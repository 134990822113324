 @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

 * {
   padding: 0px;
   margin: 0px;
   box-sizing: border-box;
 }

 .header {
   width: 100%;
   height: 100%;
 }

 .navbar {
   width: 100%;
   height: auto;
   background-color: #0a0118 !important;
   position: fixed;
   z-index: 1;
   border-bottom: 1px solid rgba(169, 169, 169, 0.192);
   padding: 0px 60px;
   display: flex !important;
   justify-content: space-between !important;
   align-items: center !important;
   top: 0;
 }

 .navbar-light .navbar-nav .nav-link {
   color: white !important;
   font-weight: 650 !important;
   margin-right: 25px !important;
   font-size: 16px !important;
   width: fit-content;
 }

 .dropdown-title-link:hover {
   color: #f775e5 !important;
 }

 .navbar-light .navbar-nav .home-link {
   color: #f775e5 !important;
 }

 .navbar-light .navbar-nav .nav-link:hover {
   color: #f775e5 !important;
   font-weight: 650 !important;
 }

 .home-link {
   color: #f775e5 !important;
 }

 .nav-link:active {
   color: #f775e5 !important;
   font-weight: 700 !important;
 }

 .navbar-toggler {
   border: 2px solid white !important;
   border-color: white !important;
   background-color: white !important;
 }

 .navbar-toggler-icon {
   border-color: white !important;
 }

 .dropdown-menu {
   width: 30px;
   display: none;
   background-color: #21192c !important;
   padding: 0px;
   border-radius: 10px;
   margin-top: 80px;
   margin-right: -10px;
   border: none;
 }

 .dropdown-item {
   padding: 13px 0px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   color: white !important;
   font-weight: 500;
 }

 .navbar-nav .dropdown-menu {
   margin-top: 22px !important;
 }

 .dropdown-item:hover {
   border-radius: 8px;
   background-color: white !important;
   color: #0a0118 !important;
 }

 .dropdown-title-link {
   text-decoration: none;
   color: inherit;
 }

 .dropdown-title-link:hover {
   color: #0056b3;
 }

 #basic-nav-dropdown:hover {
   display: block;
 }

 .navbar-light .navbar-nav .home-link {
   color: #f775e5 !important;
 }

 @media (max-width: 991px) {
   .navbar-collapse {
     background-color: #21192c !important;
   }

   .dropdown-toggle {
     background-color: #21192c;
   }

   .menu-icon {
     display: block;
   }

   .nav-elements {
     display: none;
   }

   .nav-elements.active {
     display: block;
   }

   .nav-link {
     padding-left: 30px !important;
     margin-top: 5px;
   }

   .nav-link:hover {
     color: rgb(5, 5, 5) !important;
     font-weight: 650 !important;
     margin-right: 25px !important;
     font-size: 16px !important;
     background-color: white !important;
     width: 100% !important;
     padding-left: 30px !important;
     border-radius: 10px;
   }

   .navbar-light .navbar-nav .home-link {
     color: #f775e5 !important;
   }

   .menu-icon {
     display: block;
     cursor: pointer;
   }

   .nav-elements {
     position: absolute;
     right: 0;
     top: 85px;
     background-color: #21192c !important;
     height: calc(100vh - 60px);
     overflow: hidden;
   }

   .nav-elements.active {
     width: 270px;
   }

   .nav-elements ul {
     display: flex;
     flex-direction: column;
   }

   .nav-elements ul li {
     margin-right: unset;
     margin-top: 22px;
   }

   .dropdown-menu {
     width: 100%;
     display: none;
     padding: 0px;
     border-radius: 10px;
     border: none;
     background-color: #111011 !important;
     /* margin: 10px; */
   }

   .navbar-nav .dropdown-menu {
     margin-top: 0px !important;
   }

   .dropdown-item {
     padding: 8px 0px;
     display: flex !important;
     flex-direction: column !important;
     justify-content: center !important;
     align-items: center !important;
     width: 100px;
     color: rgb(252, 245, 245) !important;
     text-align: center;
     padding-left: 50px;

   }

   .dropdown-item:hover {
     background-color: #0a0118 !important;
     color: white !important;
   }

   .navbar {
     padding: 10px 0px !important;
   }

   .navbar-brand {
     padding-left: 30px !important;
   }

   .navbar-toggler {
     margin-right: 30px !important;
     background-color: transparent;
   }
 }

 @media (max-width: 768px) {
   .container {
     padding: 8px 30px;
   }

   .nav-elements ul li:not(:last-child) {
     margin-right: 20px;
   }

   .menu-icon {
     display: block;
     cursor: pointer;
   }

   .nav-elements {
     position: absolute;
     right: 0;
     top: 80px;
     background-color: #262626;
     width: 200px;
     height: calc(100vh - 60px);
     transition: all 0.3s ease-in;
     overflow: hidden;
   }

   .nav-elements.active {
     width: 270px;
   }

   .nav-elements ul {
     display: flex;
     flex-direction: column;
   }

   .nav-elements ul li {
     margin-right: unset;
     margin-top: 22px;
   }

   .navbar {
     padding: 10px 0px !important;
   }

   .navbar-brand {
     padding-left: 30px !important;
   }

   .navbar-toggler {
     margin-right: 30px !important;
   }
 }

 @media (max-width: 600px) {
   .navbar-toggler-icon {
     width: 20px !important;
   }

   .menu-icon {
     display: block;
     cursor: pointer;
   }

   .nav-elements {
     position: absolute;
     right: 0;
     top: 80px;
     background-color: #262626;
     width: 200px;
     height: calc(100vh - 60px);
     transition: all 0.3s ease-in;
     overflow: hidden;
   }

   .nav-elements.active {
     width: 270px;
   }

   .nav-elements ul {
     display: flex;
     flex-direction: column;
   }

   .nav-elements ul li {
     margin-right: unset;
     margin-top: 22px;
   }
 }

 /* 
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.header {
  width: 100%;
  height: 100%;
}

.navbar {
  width: 100%;
  height: auto;
  background-color: #0a0118 !important;
  position: fixed;
  z-index: 1;
  border-bottom: 1px solid rgba(169, 169, 169, 0.192);
  padding: 10px 10px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  top: 0;
}

.navbar-light .navbar-nav .nav-link {
  color: white !important;
  font-weight: 650 !important;
  margin-right: 25px !important;
  font-size: 16px !important;
  width: fit-content;
}

.navbar-light .navbar-nav .home-link {
  color: #f775e5 !important;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #f775e5 !important;
  font-weight: 650 !important;
}

.nav-link:active {
  color: #f775e5 !important;
  font-weight: 700 !important;
}

.navbar-toggler {
  border: 2px solid white !important;
  background-color: white !important;
}

.navbar-toggler-icon {
  border-color: white !important;
}

.dropdown-menu {
  background-color: #21192c !important;
  padding: 0;
  border-radius: 10px;
  border: none;
}

.dropdown-item {
  color: white !important;
  font-weight: 500;
}

.dropdown-item:hover {
  background-color: white !important;
  color: #0a0118 !important;
}

.dropdown-title-link {
  color: inherit;
  text-decoration: none;
}

.dropdown-title-link:hover {
  color: #0056b3;
}

@media (max-width: 991px) {
  .navbar-collapse {
    background-color: #21192c !important;
  }

  .nav-link {
    padding-left: 30px !important;
    margin-top: 5px;
  }

  .nav-link:hover {
    color: rgb(5, 5, 5) !important;
    font-weight: 650 !important;
    background-color: white !important;
    padding-left: 30px !important;
    border-radius: 10px;
  }

  .menu-icon {
    display: block;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 85px;
    background-color: #21192c !important;
    height: calc(100vh - 60px);
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 10px 0 !important;
  }

  .navbar-brand {
    padding-left: 30px !important;
  }

  .navbar-toggler {
    margin-right: 30px !important;
  }
}

@media (max-width: 600px) {
  .navbar-toggler-icon {
    width: 20px !important;
  }
} */