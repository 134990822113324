.right_arrow {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: linear-gradient(to right, #b47cfd 0%, #ff7fc2 100%);
}

@media screen and (max-width:768px) {
  .right_arrow {
    height: 35px;
    width: 35px;
  }
}