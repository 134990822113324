.service_page {
  height: auto;
  width: 100%;
  background-color: #0a0118;
  padding: 0px;
  margin: 0px;
}

.service-price {
  height: auto;
}