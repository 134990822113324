.project-section {
  height: auto;
  width: 100%;

}

.pcard-container {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap !important;
  padding-left: 20px;

}

.pcard {
  flex-basis: 31%;
  margin: 10px 10px;
  text-align: center;
  padding: 15px 0px;
}

.phead {
  background-image: radial-gradient(at top left, #2b2338 0%, #0a0118 100%);
  padding: 20px;
  border-radius: 15px 15px 0px 0px;
  border: 1px solid rgb(46, 41, 41);
  display: flex;
  flex-direction: column;
  justify-content: left !important;

}

.pcard-img img {
  height: 450px;
  width: 400px;
  border-radius: 0px 0px 15px 15px;
  border: none;
  border: 1px solid rgb(46, 41, 41);
  opacity: 0.4;

}

.pcard-img img:hover {
  opacity: 1;
}

.phead h6 {
  color: #f775e5;
  line-height: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.phead h4 {
  color: white;
  line-height: 20px;
}

@media screen and (max-width: 1303px) {
  .pcard-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .pcard {
    margin-right: 50px;
  }
}

@media screen and (max-width: 956px) {
  .pcard {
    margin-right: 30px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 900px) {
  .pcard {
    margin-right: 10px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 863px) {
  .pcard {
    margin-right: 10px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 750px) {
  .pcard {
    margin-right: 0px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 480px) {
  .project-section {
    overflow: hidden;
  }

  .pcard-container {
    padding-left: 0px;
  }

  .pcard {
    flex-basis: auto;
  }
}